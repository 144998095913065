import * as Styled from './style'

export const CardSeguradoras = ({titulo, telefone1, telefone2, telefone3, telefone4}) => {
  return (
    <Styled.Wrapper>
      <Styled.Title>{titulo}</Styled.Title>
      <Styled.Infos>
        <Styled.Text>{telefone1}</Styled.Text>
        <Styled.Text>{telefone2}</Styled.Text>
        {telefone3 && <Styled.Text>{telefone3}</Styled.Text>}
        {telefone4 && <Styled.Text>{telefone4}</Styled.Text>}
      </Styled.Infos>
    </Styled.Wrapper>
  )
}