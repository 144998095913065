import styled from "styled-components";

export const ContainerTab = styled.div`
  width: 100%;
  background-color: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  margin-bottom: 2rem;
  cursor:pointer;
  border-radius:10px;

`;

export const HeaderTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;

  h3{
    color: #1E5F2E;
  }
`;

export const ConteudoTab = styled.div`
  max-height: ${(props) => props.aberto ? '1500px' : '0'};
  flex-direction: column;
  align-items: flex-start;
  transition: all .8s;
  overflow-y: hidden;
  padding: 0.5rem;
  padding-bottom: 0;
`;
