import styled from 'styled-components';

export const ContainerConsorcioCliente = styled.div`
    height: calc(100vh );
`;

export const IFrameConsorcioCliente = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;
