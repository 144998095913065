import styled from 'styled-components';

export const ContainerTotal = styled.div`
  overflow: hidden;
`

export const WrapperCards = styled.div`
    background:#F8F8F8;
`
export const ContainerHome = styled.div`
    display: grid;
    grid-template-columns:1fr 1fr;
    gap:30px;
    max-width: 1250px;
    margin: 0 auto;
    padding: 5rem 1rem;
    z-index: 1;

    @media (max-width:1300px){
        gap:50px;
    }
    @media (max-width:1100px){
        gap:30px;
    }
    @media (min-width: 320px) and (max-width: 1050px) {
        grid-template-columns:1fr;
        padding: 2rem;
        gap:40px;

    }
`

export const ContainerSlider = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 50px auto;
    
    img{
        width: 100%;
    }

    @media (max-width: 450px){
    }
`