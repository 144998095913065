import styled from 'styled-components';

export const Title = styled.h3`
  font-size:16px;
  padding:15px 20px;
  background:#888;
  color:#fff;
  border-radius:10px 10px 0 0;
`;

export const Wrapper = styled.div`
  background: #fff;
  display: inline-block;
  max-width:290px;
  width:100%;
  height:180px;
  transition:all .3s;
  border-radius:10px ;
  
  @media (min-width: 750px){
    &:hover{
      transform:scale(1.02);

      ${Title}{
        background:#000;
      }
    };
  }

`;



export const Infos = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  padding:20px;
`

export const Text = styled.p`
  font-size:14px;
`;