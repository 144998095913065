import React from 'react';

import {
  LinkPadrao, Section,
  SectionTitulo,
  SubsectionTitulo, Texto, Titulo
} from '../../components/common/styles/PáginaPadrao';
import Table from '../../components/Table';
import { cookiesDesempenho, cookiesFuncionalidade, cookiesNecessarios } from '../../data/cookies';

import {
  Container,
  ContainerTable
} from './styles';

function PoliticaCookies() {

  return (
    <Container>
      <Titulo>POLÍTICA DE COOKIES</Titulo>
      <Texto>Nós da <strong>Banese Administradora e Corretora de Seguros Ltda.</strong> (Banese Corretora) temos compromisso com a transparência. A presente política foi elaborada para informar quais Cookies são utilizados no nosso site (<a href="http://www.banesecorretora.com.br">www.banesecorretora.com.br</a>). Em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/18), nós pedimos o seu consentimento para a utilização dos cookies (exceto os cookies estritamente necessários para o funcionamento da plataforma).</Texto>
      <Section>
        <SectionTitulo>O QUE SÃO COOKIES?</SectionTitulo>
        <Texto>Cookies são pequenos pedaços de texto, inseridos e utilizados no site, para tornar a experiência do usuário mais eficiente e agradável, como, por exemplo, lembrando seus hábitos de navegação, preferência, informação de login e etc.</Texto>
        <Texto>As informações coletadas são apenas referentes da navegação, não contendo informações sensíveis como dados bancários.</Texto>
      </Section>
      <Section>
        <SectionTitulo>POR QUE UTILIZAMOS COOKIES?</SectionTitulo>
        <Texto>A <strong>BANESE CORRETORA</strong> utiliza cookies para tornar a experiência de navegação do usuário no site mais atrativa, lembrando as preferências e personalizando conteúdo com base nas escolhas e comportamento de navegação do usuário.</Texto>
        <Texto>Com isso, buscamos ajustar o conteúdo do nosso site para torna-lo mais relevante para você. Facilitando sua navegação ao mesmo tempo que auxilia nossos esforços promocionais e de marketing. </Texto>
      </Section>
      <Section>
        <SectionTitulo>QUAIS COOKIES UTILIZAMOS?</SectionTitulo>
        <Texto>Seguem os cookies utilizados por nós:</Texto>
        <SubsectionTitulo>Cookies estritamente necessários</SubsectionTitulo>
        <Texto>São os cookies básicos e essenciais para que o site funcione. Eles habilitam recursos de usabilidade, login, segurança, entre outros. Estes cookies, por padrão, devem permanecer ativos para o correto funcionamento do site.</Texto>
        <ContainerTable>
          <Table head={['Nome do Cookie', 'Provedor', 'Expira em']} body={cookiesNecessarios} />
        </ContainerTable>
        <SubsectionTitulo>Cookies de funcionalidade</SubsectionTitulo>
        <Texto>Cookies que permitem que nosso site registre suas preferências/escolhas, como, por exemplo, idioma do site, apresentação de conteúdo, informação de login, entre outros. São eles:</Texto>
        <ContainerTable>
          <Table head={['Nome do Cookie', 'Provedor', 'Expira em']} body={cookiesFuncionalidade} />
        </ContainerTable>
        <SubsectionTitulo>Cookies de desempenho</SubsectionTitulo>
        <Texto>Cookies que nos permitem entender como os clientes interagem com nosso site, avaliando páginas mais e menos acessadas e outras informações estatísticas. Estes cookies coletam apenas dados anonimizado, não sendo coletado nenhum dado pessoal. São eles:</Texto>
        <ContainerTable>
          <Table head={['Nome do Cookie', 'Provedor', 'Expira em']} body={cookiesDesempenho} />
        </ContainerTable>
      </Section>
      <Section>
        <SectionTitulo>CONTATO</SectionTitulo>
        <Texto>Para a <strong>BANESE CORRETORA</strong> a sua privacidade é muito importante, por isso, manteremos sempre esta Política atualizada e avisaremos sempre que houver alguma mudança.
        </Texto>
        <Texto>Em caso de dúvidas sobre esta Política ou qualquer assunto referente ao tratamento dos seus dados pessoais, entre em contato com nosso DPO pelo canal oficial disponibilizado em nosso <LinkPadrao to='/protecao-de-dados'>site</LinkPadrao>, ou através do e-mail (dpo@banesecorretora.com.br).</Texto>
      </Section>
    </Container>
  );
}

export default PoliticaCookies;