import React, { useEffect } from "react";
import {
  ContainerPage,
  ContainerBeneficios,
  SectionBeneficios,
  SectionForm,
  ContainerCards,
} from "../../components/common/styles/PáginaPadrao";

import IconPrevPrivada from "../../content/images/png/BanesePrevIcon.png";
import IconFile from "../../content/images/png/iconFile.png";
import IconRelatorioMoney from "../../content/images/png/iconRelatorioMoney.png";
import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";
import Card from "../../components/Card";
import ReactPixel from 'react-facebook-pixel';

function PrevidenciaPrivada() {
  useEffect(() => {
    ReactPixel.track('Previdencia Privada');
  }, []);

  return (
    <>
      <SectionForm>
        <ContainerPage>
          <ConsorcioTitulo
            icone={IconPrevPrivada}
            titulo="Banese Prev"
          />
          <Form nomeConsorcio="Banese Prev"></Form>
        </ContainerPage>
      </SectionForm>
      <SectionBeneficios>
        <ContainerBeneficios>
          <h3>
            Veja agora os benefícios do investimento ideal para melhor seu futuro
          </h3>
          <ContainerCards>
            <Card heigth="437px" icone={IconRelatorioMoney} conteudo="(Plano Gerador de Benefícios Livre): ideal para quem declara o imposto de Renda pelo modelo completo. As contribuições pagas dão direito ao incentivo fiscal de dedução da base de cálculo de imposto de Renda ou na declaração anual, limitado a 12% da sua renda bruta anual tributável, reduzindo o valor a ser pago ou aumentando o valor da restituição." />
            <Card heigth="437px" icone={IconFile} conteudo="(Vida Gerador de Benefícios Livre): 
            Perfeito pra quem utiliza o modelo simplificado de declaração de imposto de Renda ou é isento. 
            O imposto incide somente sobre os rendimentos do plano quando ocorrer resgate e/ou no recebimento da idenização 
            sob a mesma forma de renda. Também é indicador pra quem quer diversificar seus investimentos ou deseja 
            aplicar mais de 12% de seua renda bruta em previdência (somente o rendimento será tributado de acordo com o 
            período entre a contribuição e o resgate , podendo variar de 35% a 10%). Nesse plano não há incentivo fiscal, pórem, 
            ro resgate, o IR incide somente sobre o ganho do capital." />
          </ContainerCards>
        </ContainerBeneficios>
      </SectionBeneficios>
    </>
  );
}

export default PrevidenciaPrivada;
