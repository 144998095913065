import styled from 'styled-components';

export const LoadingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.div`
  p {
    margin: 8px 0;
    text-align: center;
  }

  textarea {
    width: 100%;
    padding: .5rem 1rem;
  }

  section {
    margin: 24px 0;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input, textarea {
    padding: 1rem;
    border-radius: 8px;
    outline: none;
    border: 2px solid #fff;

    &:focus{
      border:2px solid #1E5F2E;
    }
  }

  textarea {
    margin-top: 8px;
  }

  .loading-container {
    height: 100vh;
  }
`;

export const TextInputsContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  

`;

export const RadioInputsContainer = styled.section`
  margin-top: 8px;

  input[type=radio] {
    margin-right: 4px;
  }

  .container-checks{
      display:grid;
      grid-template-columns:1fr 1fr;
      justify-content:space-between;
      max-width:3000px;
      width:100%;
      margin:0 auto;
      gap:100px;

      @media(max-width:800px){
        grid-template-columns:1fr;
        gap:0;
      }
  }

  .container-check {
    margin: 8px 0;
    
  }
`;

export const TextoCanalDPO = styled.p`
  margin-bottom: 20px;
`;