import React, { useEffect } from 'react';
import {
  ContainerBeneficios, ContainerCards, ContainerPage, SectionBeneficios,
  SectionForm
} from "../../components/common/styles/PáginaPadrao";

import ReactPixel from 'react-facebook-pixel';
import Card from "../../components/Card";
import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";
import IconConsorcio from "../../content/images/png/ConsorcioIcon.png";
import IconRelatorio from "../../content/images/png/iconRelatorio.png";
import IconSetaDown from "../../content/images/png/iconSetaDown.png";

function Consorcio() {
  useEffect(() => {
    ReactPixel.track('Consórcio');
  }, []);

  return (
    <>
      <SectionForm>
        <ContainerPage>
          <ConsorcioTitulo
            icone={IconConsorcio}
            titulo="Consórcio"
          />
          <Form nomeConsorcio="Consórcio"></Form>
        </ContainerPage>
      </SectionForm>
      <SectionBeneficios>
        <ContainerBeneficios>
          <h3>
            Conheça todos os benefícios do nosso consórcio.
          </h3>
          <ContainerCards>
            <Card heigth="198px" icone={IconRelatorio} conteudo="Escolha o prazo de acordo com seu desejo: até 40 parcelas para serviços, 70 parcelas para motos, 80 parcelas para automóveis e 180 parcelas para imóveis. Parcelas Fléxiveis no valor que se encaixa no seu orçamento." />
            <Card heigth="198px" icone={IconSetaDown} conteudo="Tire os seu sonhos do papel de maneira programada e segura; sem juros e com as melhores taxas; use o valor da sua carta de crédito para quitar financiamentos; o valor final da sua carta de crédito será corrigido de acordo com os índices do produto escolhido." />
          </ContainerCards>
          {/* <ContainerSimulador>
                <h3>Quer conhecer melhor o nosso Seguro de Consórcio?</h3>
                <ButtonSimulador to='/simuladorconsorcio' >FAÇA UMA SIMULAÇÃO</ButtonSimulador>
              </ContainerSimulador> */}
        </ContainerBeneficios>
      </SectionBeneficios>
    </>
  );
}

export default Consorcio;