import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import {ContainerDescricao, ContainerTab} from './style';

function ItemTab({titulo, descricao, telefone1, telefone2, telefone3, telefone4}) {
  return (
    <ContainerTab>
        <FontAwesomeIcon icon={faAngleRight}/>
        <ContainerDescricao>
            <p><strong>{titulo}</strong>{descricao}</p>
            <p>{telefone1}</p>
            <p>{telefone2}</p>
            <p>{telefone3}</p>
            <p>{telefone4}</p>
        </ContainerDescricao>
    </ContainerTab>
  );
}

export default ItemTab;