export const cookiesNecessarios = [
  ["_rd_experiment_version", "Rd Station", "1 ano"],
  ["_rdlps_pp", "Rd Station", "6 meses"],
  ["cookieconsent_status_<categoria>", "Rd Station", "1 ano"],
  ["<rdstation-popup-><popupId><-viewed>", "Rd Station", "De 1 dia a 03 meses"],
];

export const cookiesFuncionalidade = [
  ["_form_fields", "Rd Station", "03 meses"],
];

export const cookiesDesempenho = [
  ["rdtrk", "Rd Station", "1 ano"],
  ["trf.src", "Rd Station", "1 ano"],
  [
    "**_rd_wa_first_session.<hash> ou **_sp_wa_first_session.<hash>",
    "Rd Station",
    "30 minutos",
  ],
  [
    "**_rd_wa_first_session.<hash> ou **_sp_wa_first_session.<hash>",
    "Rd Station",
    "30 minutos",
  ],
  ["**_rd_wa_id.<hash> ou **_sp_wa_id.<hash>", "Rd Station", "30 minutos"],
  ["**_rd_wa_id<hash> ou **_sp_wa_id.<hash>", "Rd Station", "1 ano"][
    ("**_rd_wa_ses_id ou **_sp_wa_ses_id.<hash>", "Rd Station", "Meia hora")
  ],
  ["**_sp_root_domain_test_", "Rd Station", "Sessão"],
];
