import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerSeguroEletronico = styled.div``

export const ButtonSimulador = styled(Link)`
  background-color: #1E5F2E;
  color: #ffffff;
  font-weight:bold;
  border: none;
  border-radius: 20px;
  outline: none;
  text-decoration: none;
  padding: 16px 40px;
  cursor: pointer;
  max-width: 550px;
  transition:background-color .3s;
  &:hover{
    background-color: #005010;
  }
  width: 100%;
  text-align:center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  

  @media(max-width:420px){
    font-size:14px;
  }
`

export const ContainerSimulador = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TextoSimulador = styled.p`
  font-size: 15px;
  margin: 5px;
`