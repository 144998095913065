import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

// Rotas
import Footer from "../components/Footer";
import Navbar from "../components/NavBar";
import { PageSpace } from "../components/PageSpace";
import AcidentesPessoais from "../pages/AcidentesPessoais";
import BaneseMaisCuidado from "../pages/BaneseMaisCuidado";
import Capitalizacao from "../pages/Capitalizacao";
import Consorcio from "../pages/Consorcio";
import ConsorcioCliente from "../pages/ConsorcioCliente";
import FaleConosco from "../pages/FaleConosco";
import Home from "../pages/Home";
import Imprevistos from "../pages/Imprevistos";
import PoliticaCookies from "../pages/PoliticaCookies";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade";
import PrevidenciaPrivada from "../pages/PrevidenciaPrivada";
import ProtecaoDeDados from "../pages/ProtecaoDeDados";
import QuemSomos from "../pages/QuemSomos";
import SeguroAuto from "../pages/SeguroAuto";
import SeguroBike from "../pages/SeguroBike";
import SeguroDeVida from "../pages/SeguroDeVida";
import SeguroDeVidaEmGrupo from "../pages/SeguroDeVidaEmGrupo";
import SeguroEletronico from "../pages/SeguroEletronico";
import SeguroEmpresarial from "../pages/SeguroEmpresarial";
import SeguroEventos from "../pages/SeguroEventos";
import SeguroPet from "../pages/SeguroPet";
import SeguroRcProfissional from "../pages/SeguroRcProfissional";
import SeguroRenda from "../pages/SeguroRenda";
import SeguroResidencial from "../pages/SeguroResidencial";
import SeguroViagem from "../pages/SeguroViagem";
import SimuladorConsorcio from "../pages/SimuladorConsorcio";
import SimuladorSeguroEletronico from "../pages/SimuladorSeguroEletronico";
import TermosDeUso from "../pages/TermosDeUso";
import CanalDoDPO from "../pages/CanalDoDPO";

const AppRoutes = () => {
  const { pathname } = useLocation();
  const noFooterPages = ['/simuladorseguroeletronico', '/simuladorconsorcio']

  const resetScrollOnRouteChange = () => window.scrollTo(0, 0)

  useEffect(() => {
    resetScrollOnRouteChange();
  }, [pathname])

  return (
    <>
      <Navbar />
      <PageSpace />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/quemsomos" component={QuemSomos} />
        <Route path="/faleconosco" component={FaleConosco} />
        <Route path="/imprevistos" component={Imprevistos} />
        <Route path="/seguroeletronico" component={SeguroEletronico} />
        <Route path="/politica-de-cookies" component={PoliticaCookies} />
        <Route path="/politica-de-privacidade" component={PoliticaPrivacidade} />
        <Route path="/termos-de-uso" component={TermosDeUso} />
        <Route path="/canal-do-dpo" component={CanalDoDPO} />
        <Route path="/protecao-de-dados" component={ProtecaoDeDados} />
        <Route

          path="/previdenciaprivada"
          component={PrevidenciaPrivada}
        />
        <Route path="/segurodevida" component={SeguroDeVida} />
        <Route path="/banesemaiscuidado" component={BaneseMaisCuidado} />
        <Route path="/acidentespessoais" component={AcidentesPessoais} />
        <Route path="/capitalizacao" component={Capitalizacao} />
        <Route path="/consorcio" component={Consorcio} />
        <Route path="/consorciocliente" component={ConsorcioCliente} />
        <Route path="/seguroBike" component={SeguroBike} />
        <Route path="/seguroeventos" component={SeguroEventos} />
        <Route path="/seguroviagem" component={SeguroViagem} />
        <Route path="/seguroauto" component={SeguroAuto} />
        <Route path="/seguroresidencial" component={SeguroResidencial} />
        <Route path="/seguroempresarial" component={SeguroEmpresarial} />
        <Route path="/segurodevidaemgrupo" component={SeguroDeVidaEmGrupo} />
        <Route path='/segurorcprofissional' component={SeguroRcProfissional} />
        <Route path="/segurorenda" component={SeguroRenda} />
        <Route path="/planopet" component={SeguroPet} />
        <Route path="/simuladorseguroeletronico" component={SimuladorSeguroEletronico} />
        <Route path="/simuladorconsorcio" component={SimuladorConsorcio} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      {!noFooterPages.find(item => item === pathname) && <Footer />}
    </>
  );
};

export default AppRoutes;
