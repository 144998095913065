import React from "react";

import {
  Texto,
} from "../../components/common/styles/PáginaPadrao";

import { Container, Titulo } from "./styles";

function CanalDoDPO() {
  return (
    <Container>
      <Titulo>Canal do DPO</Titulo>
      <Texto>
      Este Canal é de uso exclusivo para dúvidas e solicitações sobre seus dados pessoais como:
      confirmação da existência de tratamento de seus dados pessoais, solicitação de correção,
      eliminação, bloqueio ou atualização de seus dados pessoais do nosso banco de dados ou
      revogar consentimento de uso de seus dados pessoais. Para outros assuntos, utilize o canal Fale
      Conosco.
      </Texto>
      <Texto>
      Nós da <strong>Banese Corretora</strong> valorizamos a privacidade e proteção dos seus dados pessoais. Seus
      dados coletados neste canal de atendimento, como: nome completo, CPF, número de celular,
      e-mail e quaisquer outros dados por você informado serão para única e exclusiva finalidade de
      atender seus direitos como titular, previstos no artigo 18, Lei 13.709/2018 - Lei Geral de
      Proteção de Dados Pessoais. Os dados não serão divulgados a terceiros, a menos que você
      tenha concordado, que sejamos obrigados por lei ou que tenhamos previamente informado
      você, e descartaremos seus dados pessoais após 10 anos, salvaguarda os armazenamentos
      previstos em leis e obrigações legais.
      </Texto>
      <Texto style={{ fontSize: 14, color: "gray", textAlign: "end" }}>
        Atualizado pela última vez em 04 de Setembro de 2023
      </Texto>{" "}
    </Container>
  );
}

export default CanalDoDPO;
