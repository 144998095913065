import React from 'react';
import {ContainerCard, ConteudoCard, HeaderCard} from './style';

function Card({icone, conteudo, heigth, titulo}) {
  return (
    <ContainerCard>
        <HeaderCard>
            <img src={icone} alt="icone"/>
        </HeaderCard>
        <ConteudoCard heigth={heigth}>
            <strong>{titulo}</strong>
            <p>{conteudo}</p>
        </ConteudoCard>
    </ContainerCard>
  );
}

export default Card;