import React, { useEffect } from "react";
import {
  ContainerPage,
  SectionPage,
} from "../../components/common/styles/PáginaPadrao";
import Titulo from "../../components/TituloColumn";
import Tab from "../../components/Tab";
import ItemTab from '../../components/ItemTab'

import IconQuemSomos from "../../content/images/png/iconQuemSomos.png";
import ReactPixel from 'react-facebook-pixel';
import { ContainerPageQuemSomos, SectionQuemSomos } from "../FaleConosco/style";


function QuemSomos() {
  useEffect(() => {
    ReactPixel.track('Quem somos');
  }, []);

  return (
    <>
      <SectionQuemSomos>
        <ContainerPageQuemSomos>
          <Titulo icone={IconQuemSomos} titulo={"QUEM SOMOS?"} />
          <Tab titulo="A Banese Corretora">
            <p>
              Com mais de 40 anos de mercado, oferecemos soluções de seguros
              diferenciadas, adequadas às necessidades do cliente, no menor
              prazo e com custos competitivos e acessíveis, através das
              parcerias com as maiores companhias seguradoras do país.
            </p>
          </Tab>
          <Tab titulo="Missão">
            <p>
              Prestar serviços de assessoria e orientação técnica na contratação
              de benefícios e seguros, identificando as melhores coberturas
              securitárias, com o intuito de atender e garantir a devida
              tranquilidade aos clientes
            </p>
          </Tab>
          <Tab titulo="Valores">
            <ItemTab titulo="Transparência " descricao="solidifica a confiança em nossa marca, na conduta ética e de todas nossas ações;"/>
            <ItemTab titulo="Excelência " descricao="norteia nosso profissionalismo em tudo que fazemos;"/>
            <ItemTab titulo="Foco nas pessoas, " descricao="sejam elas físicas ou jurídicas, nossa razão de existência;"/>
            <ItemTab titulo="Foco em resultados " descricao="promove a perenidade dos nossos negócios;"/>
            <ItemTab titulo="Qualidade de vida " descricao="com a oferta de produtos que contribuem para a segurança, o bem-estar, a saúde física e a estabilidade financeira das pessoas e empresas;"/>
          </Tab>
        </ContainerPageQuemSomos>
      </SectionQuemSomos>
    </>
  );
}

export default QuemSomos;
