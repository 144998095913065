import { css } from "@emotion/core";
import emailjs from "emailjs-com";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import TermoLGPD from "../../documents/CM01-Comunicado-de-adquação-LGPD.pdf";
import CardSucesso from "../CardSucesso";
import { ButtonForm, LinkPadrao } from "../common/styles/PáginaPadrao";
import { CelMask } from "../utils/MetodosDeRenderizacao";
import { FormContainer, LoadingContainer } from "./style";

function Form({ nomeConsorcio }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [seguro, setSeguro] = useState("");
  const [telefone, setTelefone] = useState("");
  const [dados, setDados] = useState(false);
  const [termo, setTermo] = useState(false);
  const [info, setInfo] = useState(false);
  const [aceite, setAceite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conteudo, setConteudo] = useState(false);
  const [conveniadoOab, setConveniadoOab] = useState("Não");
  const [servidorBanese, setServidorBanese] = useState("Não");
  const [renovacao, setRenovacao] = useState("Não");

  const styleLoader = css`
    margin: 0 auto;
    width: 70px;
    height: 70px;
    display: flex;
    margin-top: 5rem;
    color: #00938f;
  `;

  useEffect(() => {
    setSeguro(nomeConsorcio);
    if (
      name &&
      email &&
      telefone &&
      seguro !== "" &&
      termo !== false &&
      info !== false &&
      aceite !== false
    ) {
      setDados(true);
    } else {
      setDados(false);
    }
  }, [name, email, telefone, seguro, termo, info, aceite, nomeConsorcio]);

  const enviarEmail = (e) => {
    e.preventDefault();
    console.log(e.target);
    setLoading(true);
    emailjs
      .sendForm(
        "service_xsjvpec",
        "template_1dvikzr",
        e.target,
        "user_4bJ52lJvQN4nrcOZLAWXr"
      )
      .then(
        (result) => {
          if (result.status === 200 || result.status === 204) {
            setLoading(false);
            setConteudo(true);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      {loading ? (
        <LoadingContainer>
          <ClipLoader css={styleLoader} color="#00938f" />
        </LoadingContainer>
      ) : !conteudo ? (
        <FormContainer onSubmit={enviarEmail}>
          <div className='paragrafo'>
            <p>Campos com * são obrigatórios</p>
          </div>
          <input
            type="text"
            placeholder="Nome*"
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
            required
            autoComplete="off"
            autoFocus
          />

          <input
            type="email"
            placeholder="Email*"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="off"
          />
          <input
            type="text"
            placeholder="(00) 0 0000-0000*"
            maxLength={15}
            value={telefone}
            name="telefone"
            onChange={(e) => setTelefone(CelMask(e.target.value))}
            autoComplete="off"
            required
          />
          <div className="container-select">
            <select
              name="seguro"
              value={seguro}
              onChange={(e) => setSeguro(e.target.value)}
              required
            >
              <option value={seguro} defaultValue>
                {seguro}
              </option>
            </select>
          </div>

          <div className="container-select">
            <label htmlFor="renovacao">
              Renovação
            </label>
            <select
              name="renovacao"
              value={renovacao}
              onChange={(e) => setRenovacao(e.target.value)}
            >
              <option value="Não" defaultValue>
                Não
              </option>
              <option value="Sim">
                Sim
              </option>
            </select>
          </div>


          <div className="container-select">
            <label htmlFor="conveniado-oab">
              Conveniado OAB
            </label>
            <select
              name="conveniado-oab"
              value={conveniadoOab}
              onChange={(e) => setConveniadoOab(e.target.value)}
            >
              <option value="Não" defaultValue>
                Não
              </option>
              <option value="Sim">
                Sim
              </option>
            </select>
          </div>

          <div className="container-select">
            <label htmlFor="servidor-banese">
              Servidor Banese
            </label>
            <select
              name="servidor-banese"
              value={servidorBanese}
              onChange={(e) => setServidorBanese(e.target.value)}
            >
              <option value="Não" defaultValue>
                Não
              </option>
              <option value="Sim">
                Sim
              </option>
            </select>
          </div>

          <div className="container-checks">
            <div className="container-check-termo">
              <label htmlFor="termo">
                Li e concordo com os{" "}
                <LinkPadrao to="/termos-de-uso">Termos de uso</LinkPadrao>
                {" "}e a{" "}
                <LinkPadrao to="/politica-de-privacidade">Política de Privacidade</LinkPadrao>
              </label>
              <input
                type="checkbox"
                id="termo"
                value="termo"
                checked={termo}
                onChange={(e) => setTermo(e.target.checked)}
              />
            </div>
            <div className="container-check-infos">
              <label htmlFor="infos">
                Concordo em compartilhar minhas informações com a Banese
                Corretora e Corretores parceiros para realizar meu orçamento e
                comercialização dos produtos e serviços.
              </label>
              <input
                type="checkbox"
                id="infos"
                value="infos"
                checked={info}
                onChange={(e) => setInfo(e.target.checked)}
              />
            </div>
            <div className="container-check-aceite">
              <label htmlFor="aceite">
                Aceito receber comunicação sobre ofertas e promoções da Banese
                Corretora
              </label>
              <input
                type="checkbox"
                id="aceite"
                value="aceite"
                checked={aceite}
                onChange={(e) => setAceite(e.target.checked)}
              />
            </div>
          </div>
          <div className="button">
            <ButtonForm isEnable={dados} type="submit">
              Enviar
            </ButtonForm>
          </div>
        </FormContainer>
      ) : (
        <CardSucesso
          subtitulo="A MELHOR COISA É SEMPRE FUGIR DO IMPREVISTO!"
          mensagem="Recebemos suas informações e entraremos em contato em breve!"
        />
      )}
    </>
  );
}

export default Form;
