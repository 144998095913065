import React, { useEffect } from 'react';
import {
  ContainerPage,
  SectionForm
} from "../../components/common/styles/PáginaPadrao";

import IconMaisCuidado from "../../content/images/png/Banese+CuidadoIcon.png";

import ReactPixel from 'react-facebook-pixel';
import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";

function BaneseMaisCuidado() {
  useEffect(() => {
    ReactPixel.track('Banese + Cuidado');
  }, []);

  return (
    <>
      <SectionForm>
        <ContainerPage>
          <ConsorcioTitulo icone={IconMaisCuidado} titulo="Banese +Cuidado" />
          <Form nomeConsorcio="Banese +Cuidado"></Form>
        </ContainerPage>
      </SectionForm>

    </>
  );
}

export default BaneseMaisCuidado;