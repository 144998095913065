import styled from 'styled-components';
import { NavLink ,Link} from 'react-router-dom';

export const Wrapper = styled.div`
  background:#fff;
  position:fixed;
  top:0;
  left:0;
  right:0;
  z-index:10000;
`;

export const MenuContent = styled.div`
  margin:0 auto;
  max-width:1200px;
  padding:0 15px;
  display:flex;
  justify-content: space-between;
  align-items:center;
  height:120px;
  svg{
    display:none;
    
  }
  @media(max-width:1000px){
    justify-content:center;
    img{
      align-self:center;
      margin:0 auto;
    }
    svg{
      position:absolute;
      top:50px;
      right:15px;
      cursor:pointer;
      display:block;
      margin-right:15px;
      transform:scale(1.8);
      &:hover{
        filter:brightness(.7)
      }
    }
  }
`;

export const Logo = styled(Link)`
  img{
    max-width: 160px;
  }

  @media (max-width:1000px){
    margin-left:30px;
    img{
      max-width:160px;
    }
  }
`;

export const Menu = styled.nav`
  display:flex;
  align-items:center;
  @media(max-width:1210px){
    justify-content:space-between;
  }
  @media(max-width:1000px){
    display:none;
  }
  .nav-menu.active{
    background: #000;
    height:100vh;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;
    z-index:20000000;
  }
`;

export const MenuLinks = styled.ul`
  @media(max-width:1150px){
    width:70%;
    text-align:right;
  }
  .active{
    color:#1E5F2E;
    pointer-events:none;
  }
`;

export const MenuItem = styled(NavLink)`
  text-decoration:none;
  margin-right:20px;
  display: inline-block;
  padding:5px ; 
  font-size:13.5px; 
  color:#777;
  font-weight:bold;

  transition:transform .2s;

  &:hover{
    transform:scale(1.015);
    color:#000;
    font-weight:bold;
  }

 

  @media(max-width:1300px){
      font-size:13px;
    margin-right:12px;

  }

  @media(max-width:1210px){
    margin-right:12px;
  }

  .selected{
    color:red;
    text-decoration:none;
  }

`

export const Button = styled.button`
  padding: 13px 16px;
  text-transform:uppercase;
  background-color: #fff;
  border: none;
  font-size:13px;
  font-weight: 700;
  border-radius: 15px;
  outline: none;
  display:flex;
  align-items:center;
  transition:background-color .3s;
  border:1px solid #888;
  transition:all .4s;
  color:000;
  @media (min-width: 320px) and (max-width: 650px) {
    background-color: #ffffff;
    color: #1E5F2E;
  }

  &:hover{
    background-color: #1E5F2E;
    border:1px solid #1E5F2E;
    a{
      color:#FFF;
    }
    svg{
      color:#fff;
      transform:scale(1.4)
    }
  }

  svg {
    display:block;
    margin-right:20px;
    transform:scale(1.4);
  }

  a{
    text-decoration:none;
    color:#000;
  }
`;

export const MobileMenu = styled.div`
  display:none;

  &.active{
    @media(max-width:1000px){
        display:flex;
        justify-content:center;
        ${MenuItem}{
          display:block;
          text-align:center;
          margin-bottom:30px;
          font-size:18px;
          color:#FFF;
          padding:20px 20px;

          &:hover{
            background:#0E5F2E;
          }
        }
      }
  }
  @media(max-width:1000px){
    display:none;
    flex-direction:column;
    position:absolute;
    bottom:0;
    left:0;
    background:#1E5F2E;
    height:100vh;
    right:0px;
    top:0px;
    svg{
      cursor:pointer;
      position:absolute;
      top:45px;
      right:30px;
      transform:scale(2);
      color:#fff;
      &:hover{
        filter:brightness(.7)
      }
    }
  }

  ${MenuItem}{
    display:none;
  }
`

export const MobileMenuWhatsappButton = styled.div`
  display:flex;
  align-items:center;
  display:block;
  a{
    text-decoration:none;
    display:block;
    text-align:center;
    font-size:18px;
    color:#FFF;
    padding:20px 20px;
    font-weight:bold;
  }
  svg{
    position:relative;
    top:0;
  }
  &:hover{
    background:#00939f;
  }
`