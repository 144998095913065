import styled from 'styled-components';

export const ContainerTituloColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto 1rem auto;
    max-width: 600px;

    img{
        margin-right: 1rem;
    }

    h1{
        color: #1E5F2E;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media(max-width:450px){
        img{
            width: 70px;
        }
    }

`