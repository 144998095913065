import styled from 'styled-components';

export const ContainerCard = styled.div`
    max-width: 400px;
    height: 100%;
    border-radius: 12px;
    padding: 1rem;
`

export const HeaderCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #1E5F2E;;
    padding: 1rem;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    img{
        height: 130px;
    }
`

export const ConteudoCard = styled.div`
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: ${props => props.heigth};

`