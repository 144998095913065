import React, { useEffect } from "react";

import MiniCard from "../../components/MiniCard";
import Titulo from "../../components/TituloColumn";
import {
  ContainerMiniCards,
} from "../../components/common/styles/PáginaPadrao";
import IconFaleConosco from "../../content/images/png/iconFaleConosco.png";
import IconTelefone from "../../content/images/png/iconTelefone.png";
import IconWhats from "../../content/images/png/iconWhats.png";
import { ContainerPageFaleConosco, SectionContatos } from './style';

import ReactPixel from 'react-facebook-pixel';
import { ButtonWhatsapp } from "../QuemSomos/style";

function FaleConosco() {

  useEffect(() => {
    ReactPixel.track('Fale Conosco')
  }, []);

  return (
    <>
      <SectionContatos isFaleConosco={true} >
        <ContainerPageFaleConosco>
          <Titulo
            icone={IconFaleConosco}
            titulo={"FALE CONOSCO"}
            descricao="Segunda a sexta, em horário comercial"
          />
          <ContainerMiniCards>
            <MiniCard icone={IconWhats} telefone="(79) 3234-4250">
              <ButtonWhatsapp className="btn-link" href={`https://wa.me/557932344250`} target="blank">Ou clique aqui</ButtonWhatsapp>
            </MiniCard>
            <MiniCard icone={IconTelefone} telefone="(79) 3234-4250" />
          </ContainerMiniCards>
        </ContainerPageFaleConosco>
      </SectionContatos>
    </>


  );
}

export default FaleConosco;
