import styled from "styled-components";

export const ContainerPage = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem;
  width: 100%;
  height:calc(100vh - 150px);
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 30px auto;
     text-align:center;
   }
   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;

export const ContainerPageFaleConosco = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem;
  width: 100%;
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 30px auto;
     text-align:center;
   }
   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;

export const ContainerPageQuemSomos = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 8rem;
  width: 100%;
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 30px auto;
     text-align:center;
   }
   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;

export const SectionQuemSomos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d0d0d0;
`;

export const SectionContatos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #d0d0d0;
`;



