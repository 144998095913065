import styled from 'styled-components';

export const ContainerFooter = styled.footer`
    background-color: #dfdfdf;
    padding: 1rem;
    color: #8c8c8c;
    width: 100%;
`

export const ConteudoFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;

    img{
        width: 160px;
    }
    
    ul{
        list-style: none;
    }

    .servicos{
        display:flex;
        text-align:center;
        li{
            margin:0 20px;
        }

        @media(max-width:750px){
          font-size:14px;
        }

        @media(max-width:450px){
            li{
                margin:0 10px;
            }   
        }
    }

    .redes{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        a{
            color: #8c8c8c;
            
        }
        li{
            margin-right: 1rem;
        }
        svg{
          transform:scale(1.4);
        }
    }
    .redes svg:hover {
        color:#1E5F2E;
        transform:scale(1.6);
        
    }


    @media (max-width: 750px) {
        flex-direction: column;
        .redes,img,ul{
            padding: 1rem;
        }
    }
`
export const Endereco = styled.div`

    max-width: 600px;
    margin: 0 auto;
    p{
        text-align: center;
        font-size: 12px;
    }
    a{
        text-decoration: none;
        color: #8c8c8c;
    }
`
