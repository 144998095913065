import React from 'react';
import * as Styled from './styles';

function ServicoCard({icone, altText, titulo, rota, children, dataLancamento}) {
  const disponivel = !dataLancamento || dataLancamento <= new Date()
  
  return (
    <Styled.Wrapper>
      <Styled.ContainerServico>
        <Styled.ContainerInfo>
          <Styled.ServicoLogo>
            <div>
              <img src={icone} alt={altText}/>
            </div>
            <h3>{titulo}</h3>
          </Styled.ServicoLogo>
          {children}
        </Styled.ContainerInfo>
      </Styled.ContainerServico>
      {
        disponivel ? <Styled.BotaoProdutos to={rota}>PEÇA AQUI SUA COTAÇÃO</Styled.BotaoProdutos> : <Styled.EmBreve>{`EM BREVE`}</Styled.EmBreve>
      }
      
    </Styled.Wrapper>
  );
}

export default ServicoCard;