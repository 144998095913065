import React, { useEffect, useRef, useState } from "react";

import { Container, FrameSimulador } from "./styles";

function SimuladorSeguroEletronico() {
  return (
      <Container>
        <FrameSimulador
          src="https://sales.88iseguradora.digital/banese-corretora/seguro-celular/choose-device/campaign/U2FsdGVkX19XXsMu1jLXddrOISZFZbsuP9QGdY5W52A="
        />
      </Container>
  );
}

export default SimuladorSeguroEletronico;
