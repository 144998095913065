import styled from 'styled-components'

export const ContainerSucesso = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 5rem;
    min-height: 100vh;

    h1,h3{
        color: #00938f;
        margin: 1rem;
    }
`