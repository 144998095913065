import React, { useEffect } from "react";
import {
  ContainerPage,
  ContainerBeneficios,
  SectionBeneficios,
  SectionForm,
  ContainerCards,
} from "../../components/common/styles/PáginaPadrao";

import IconSegEvent from "../../content/images/png/SeguroEventosIcon.png";
import IconTesoura from "../../content/images/png/iconTesoura.png";
import IconShow from "../../content/images/png/iconShow.png";

import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";
import Card from "../../components/Card";
import ReactPixel from 'react-facebook-pixel';

function SeguroEventos() {
  useEffect(() => {
    ReactPixel.track('Seguro Eventos');
  }, []);

  return (
    <>
      <SectionForm>
        <ContainerPage>
          <ConsorcioTitulo icone={IconSegEvent} titulo="Seguro de Eventos" />
          <Form nomeConsorcio="Seguro de Eventos"></Form>
        </ContainerPage>
      </SectionForm>
      <SectionBeneficios>
        <ContainerBeneficios>
          <h3>Conheça todos os benefícios do nosso seguro de eventos</h3>
          <ContainerCards>
            <Card heigth="161px" icone={IconTesoura} conteudo="Feito para pessoas físicas e jurídicas que atuam na prestação de serviços em eventos, seja na organização, na promoção ou na exposição, além de poder ser contratado também pelos clientes finais." />
            <Card heigth="161px" icone={IconShow} conteudo="Cobertura ao segurado desde a instalação e montagem da estrutura do evento até prejuízos pelo não-comparecimento do artista" />
          </ContainerCards>
        </ContainerBeneficios>
      </SectionBeneficios>
    </>
  );
}

export default SeguroEventos;
