import React, { useEffect } from 'react';
import {
    ContainerPage,
    SectionForm,
  } from "../../components/common/styles/PáginaPadrao";

  import IconSegVidaGrupo from "../../content/images/png/SeguroDeVidaGrupoIcon.png";

  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import ReactPixel from 'react-facebook-pixel';

function SeguroDeVidaEmGrupo() {
  useEffect(() => {
    ReactPixel.track('Seguro de vida em grupo');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegVidaGrupo} titulo="Seguro de Vida em Grupo" /> 
              <Form nomeConsorcio="Seguro de Vida em Grupo"></Form>
            </ContainerPage>
          </SectionForm>

        </>
      );
}

export default SeguroDeVidaEmGrupo;