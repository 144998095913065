import React, { useState } from "react";
import { ContainerTab, ConteudoTab, HeaderTab } from "./style";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

function Tab({ titulo, children }) {
    const [open, setOpen] = useState(false)

  return (
    <ContainerTab onClick={() => setOpen(!open)} >
      <HeaderTab>
        <h3>{titulo}</h3>
        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
      </HeaderTab>
      <ConteudoTab aberto={open}>
          {children}
      </ConteudoTab>
    </ContainerTab>
  );
}

export default Tab;
