import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  import IconSegRenda from "../../content/images/png/SeguroRendaGarantidaIcon.png";
  import IconPessoa from "../../content/images/png/iconPessoa.png";
  import IconCadeirante from "../../content/images/png/iconCadeirante.png";
  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';

function SeguroRenda() {
  useEffect(() => {
    ReactPixel.track('Seguro Renda');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegRenda} titulo="Seguro Renda Garantida" />
              <Form nomeConsorcio="Seguro Renda Garantida"></Form>
            </ContainerPage>
          </SectionForm>
          <SectionBeneficios>
            <ContainerBeneficios>
              <h3>Conheça todos os benefícios do nosso seguro renda garantida</h3>
              <ContainerCards>
                <Card heigth="216px" icone={IconPessoa} titulo="DIT – Diária de Incapacidade Temporária Disponível" conteudo="para celetistas ou profissionais autônomos. Pagamento de diárias em caso de afastamento total contínuo ou temporário das atividades profissionais do segurado em caso de acidente." />
                <Card heigth="216px" icone={IconCadeirante} titulo="SERIT – Seguro de Renda por Incapacidade Temporária Disponível" conteudo="para profissionais autônomos e liberais, que exercem sua atividade remunerada. Cobertura em caso de incapacidade temporária e recebimento de uma quantia em dinheiro durante todo o tempo de recuperação." />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios>
        </>
      );
}

export default SeguroRenda;