import React, { useEffect } from 'react';
import {
    ContainerPage,
    SectionForm,
  } from "../../components/common/styles/PáginaPadrao";

  import IconSegEmpresarial from "../../content/images/png/SeguroEmpresarialIcon.png";

  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import ReactPixel from 'react-facebook-pixel';

function SeguroEmpresarial() {
  useEffect(() => {
    ReactPixel.track('Seguro Empresarial');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegEmpresarial} titulo="Seguro Empresarial" /> 
              <Form nomeConsorcio="Seguro Empresarial"></Form>
            </ContainerPage>
          </SectionForm>
        </>
      );
}

export default SeguroEmpresarial;