import React from "react";
import { Container, Head, Row, Item, Body, TableContainer, HeadRow } from "./styles";

// interface TableProps{
//   head: Array<ReactNode>
//   body: Array<Array<ReactNode>| ReactNode>
// }

function Table({ head, body }) {
  return (
    <Container>
      <TableContainer>
        <HeadRow>
          {head.map((item) => (
            <Item>{item}</Item>
          ))}
        </HeadRow>
        {body.map((item) => {
          return (
            item && (
              <Row>
                {Array.isArray(item)
                  ? item.map((data) => <Item>{data}</Item>)
                  : item}
              </Row>
            )
          );
        })}
      </TableContainer>
    </Container>
  );
}

export default Table;
