import React, { useEffect } from "react";

import * as Styled from './style'

import Titulo from "../../components/TituloColumn";

import IconCuidado from "../../content/images/png/iconCuidado.png";
import ReactPixel from 'react-facebook-pixel';
import { CardSeguradoras } from "../../components/CardSeguradoras";

function Imprevistos() {
  useEffect(() => {
    ReactPixel.track('Imprevistos');
  }, []);

  return (
      <Styled.SectionContatos isFaleConosco={true} >
        <Styled.ContainerPage>
          <Titulo icone={IconCuidado} titulo={"EM CASO DE IMPREVISTO"} />
          <p className="paragrafo-contato">
            Aqui estão os números do nossos parceiros para comunicar Sinistros:
          </p>
        
          <Styled.TitleTelefone>Telefones Seguradoras (assistência/sinistro)</Styled.TitleTelefone>
          <Styled.WrapperCardsImprevisto>
            <CardSeguradoras titulo="Alfa Seguros" telefone1="Aviso de sinistro: (011) 4003 - 2532" telefone2="Assistência 24h: (011) 4003 - 2532"/>
            <CardSeguradoras titulo="ALLIANZ Seguros" telefone1="Aviso de sinistro: 4090-1110 / 0800 7777 243" telefone2="Assistência 24h: 0800 130 700" telefone3="Vidros: 0800 701 8283 / 0800 701 1170"/>
            <CardSeguradoras titulo="Azul Seguros" telefone1="Aviso de sinistro: (011) 4004 3700" telefone2="Assistência 24h: 0800 703 02 03" telefone3="Vidros: 0800 704 4416"/>
            <CardSeguradoras titulo="Bradesco Seguros" telefone1="Aviso de sinistro: 4004 - 2757" telefone2="Assistência 24h: 0800 701 2757" telefone3="Vidros: 0800 701 0303/ 0800 701 7997"/>
            <CardSeguradoras titulo="H.D.I. Seguros" telefone1="Aviso de sinistro: 3003 5390" telefone2="Assistência 24h: 0800 434 4340" telefone3="Vidros: 0800 770 9833"/>
            <CardSeguradoras titulo="Itaú Seguros" telefone1="Aviso de sinistro: 3003 1001" telefone2="Assistência 24h: 3003 1010"/>
            <CardSeguradoras titulo="Liberty Seguros" telefone1="Aviso de sinistro: 4004 - 5423" telefone2="Assistência 24h: 0800 701 4120/ 0800 701 4120 (VIDROS)" telefone3="Central Localiza: 0800 707 8484"/>
            <CardSeguradoras titulo="Mapfre Seguros" telefone1="Aviso de sinistro: 4004 - 0101" telefone2="Assistência 24h: 0800 775 1000/0800 705 0101"/>
            <CardSeguradoras titulo="Porto Seguro" telefone1="Aviso de sinistro: 0800 727 0800" telefone2="Assistência 24h: 0800 727 080" telefone3="RE: 0800 727 8118"/>
            <CardSeguradoras titulo="Sompo Seguradora S/A" telefone1="Aviso de sinistro: 0800 016 2727" telefone2="Assistência 24h: 0800 016 2727" telefone3="Vidros: 0800 707 7376"/>
            <CardSeguradoras titulo="Sompo Seguradora S/A" telefone1="Aviso de sinistro: 0800 016 2727" telefone2="Assistência 24h: 0800 016 2727" telefone3="Vidros: 0800 707 8005"/>
            <CardSeguradoras titulo="Sul América" telefone1="Aviso de sinistro: 4004 - 4100" telefone2="Assistência 24h: 4004 - 4100" telefone3="Vidros: 0800 707 7640" telefone4="Consulta andamento de sinistro: 3003-6595 / 0800 721 6595 / 3003-1162"/>
            <CardSeguradoras titulo="Tokio Marine" telefone1="Aviso de sinistro: 0300 33 865 46" telefone2="Assistência 24h: 0800 31 86546" telefone3="Vidros: 0800 707 7376"/>
            <CardSeguradoras titulo="Zurich" telefone1="Aviso de sinistro: 4020 - 4848" telefone2="Assistência 24h: 0800 729 1400" telefone3="Vidros: 0800 707 1666/ 0800 0256303"/>
          </Styled.WrapperCardsImprevisto>
        </Styled.ContainerPage>
      </Styled.SectionContatos>
  );
}

export default Imprevistos;
