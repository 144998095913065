import React, { useEffect } from 'react';
import {
    ContainerPage,
    SectionForm
  } from "../../components/common/styles/PáginaPadrao";

  import IconAcidentes from "../../content/images/png/SeguroAcidentesPessoaisIcon.png";
  import IconAcidente from "../../content/images/png/iconAcidentesPessoais.png";
  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import ReactPixel from 'react-facebook-pixel';
  
  function AcidentesPessoais() {
    useEffect(() => {
      ReactPixel.track('Acidentes Pessoais');
    }, []);

    return (
        <>
          <SectionForm isAcidentePessoal={true}>
            <ContainerPage>
              <ConsorcioTitulo
                icone={IconAcidentes}
                titulo="Acidentes Pessoais"
              />
              <Form nomeConsorcio="Acidentes Pessoais"></Form>
            </ContainerPage>
          </SectionForm>
        </>
      );
}

export default AcidentesPessoais;