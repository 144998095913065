import { Link } from 'react-router-dom';
import styled from "styled-components";

export const Button = styled.button`
  width: 220px;
  height: 30px;
  background-color: #00938f;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;

  &:hover{
    opacity: .85;
    transition: .2s ease;
  }

  @media (min-width: 320px) and (max-width: 650px) {
    background-color: #ffffff;
    color: #00938f;
  }
`;

export const LinkPadrao = styled(Link)`
  color: blue;
`;

export const ButtonMiniCard = styled.button`
  width: 120px;
  height: 30px;
  background-color: #00938f;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
`;

export const ButtonForm = styled.button`
  max-width: 320px;
  width: 100%;
  font-size:18px;
  height: 50px;
  background-color: ${props => props.isEnable ? '#1E5F2E' : '#5f6363'};
  pointer-events: ${props => props.isEnable ? 'visible' : 'none'};
  color: #ffffff;
  border: none;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  text-align: center;
  margin-top: 1rem;
  margin:2rem auto 0 auto;
  transition: background .3s;
  &:hover{
    background:#003F2E;
  }
`;

export const ContainerPage = styled.div`
  max-width: 1200px;
  margin: 0 auto ;
  padding: 2rem 0 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 0px auto;
     text-align:center;
   }
   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;


export const ContainerPageDados = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0 5rem 0;
  width: 100%;
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 0px auto;
     text-align:center;
   }
   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;


export const ContainerBeneficios = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  h3{
      width: 60%;
      margin-bottom:1.5rem;
      font-weight:bold;
      font-size:23px;
      color:#1E5F2E;;
      text-align:center;
  }

  @media (min-width: 320px) and (max-width: 1000px) {
    padding: 2rem;
    h3{
      width: 100%;
      text-align:center;
    }
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  @media (min-height: 1080px){
    max-height: 350px;
  }

  img{
    width: 50px;
    height: 50px !important;
  }
`

export const ContainerMiniCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin:0 auto;
  flex-wrap: wrap;
  padding-bottom:100px;
`

export const SectionForm = styled.div`
  background-color: #d0d0d0;
  @media (min-height: 1080px){
    height: 100vh;
  }
`;

export const SectionBeneficios = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #c3c3c3;
  @media (min-height: 1080px){
    height: ${props => props.isSeguroDeVida ? '125vh' : '100vh'};
  }
`;

export const SectionContatos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #d0d0d0;
  @media (min-height: 1080px){
    height: ${props => props.isFaleConosco ? '180vh' : '130vh'};
  }
`;

export const SectionPage = styled.div`
  background-color: #dfdfdf;

  @media (min-height: 1080px){
    height: 100vh;
  }
`;


export const Titulo = styled.h1`
  line-height: 40px;
  margin: 20px 0;
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }

  @media (max-width: 650px) {
    font-size: 1.3rem;
  }
  `

export const Texto = styled.p`
  color: rgba(0,0,0,0.8);
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin: 10px 0;

  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const TextoSublinhado = styled.span`
  color: rgba(0,0,0,0.8);
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-decoration: underline;

  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const ParagrafoSublinhado = styled.p`
  color: rgba(0,0,0,0.8);
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin: 10px 0;
  text-decoration: underline;

  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const Section = styled.section`


`

export const SectionTitulo = styled.h2`
  margin: 20px 0;
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
  @media (max-width: 650px) {
    font-size: 1.1rem;
  }
`

export const SectionLista = styled.ul`
  margin: 0px 40px;
  
  @media (max-width: 650px) {
    margin-left: 20px;
    margin-right: 0;
    
  }
`

export const SectionListaOrdenada = styled.ol``

export const SectionItemLista = styled.li`
  color: rgba(0,0,0,0.8);
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: justify;
  margin: 10px 0;
  
  ${props => props.hasIconeLista ? 'list-style: none;' : ''};
  
  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const SubsectionTitulo = styled.h3`
  margin: 20px 0;
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
`

