import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  
  import IconSegTrip from "../../content/images/png/SeguroViagemIcon.png";
  import IconViagem from "../../content/images/png/iconViagem.png";
  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';
  

function SeguroViagem() {
  useEffect(() => {
    ReactPixel.track('Seguro Viagem');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo
                icone={IconSegTrip}
                titulo="Seguro Viagem"
              />
              <Form nomeConsorcio="Seguro Viagem"></Form>
            </ContainerPage>
          </SectionForm>
          <SectionBeneficios>
            <ContainerBeneficios>
              <h3>
                Conheça todos os benefícios do nosso seguro viagem.
              </h3>
              <ContainerCards>
                <Card icone={IconViagem} conteudo="Diversos serviços e assistências para manter a sua tranquilidade durante a viagem." />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios>
        </>
      );
}

export default SeguroViagem;