import React, { useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { Button, LinkPadrao } from "../common/styles/PáginaPadrao";
import {
  ContainerConteudo,
  ContainerCookie, ContainerCookieOption, ContainerCookieSelect, CookieButtons, CookieCheckbox, CookieOptions, CookieOptionsContainer, CookieType, InfoCookie,
  InfoCookieDescricao
} from "./style";

function Cookies() {
  const cookiePersonalizacaoRef = useRef();
  const cookieMarketingRef = useRef();
  const cookieAnalyticsRef = useRef();
  const cookieNaoCategorizadoRef = useRef();
  const [open, setOpen] = useState(true);
  const [openPreferences, setOpenPreferences] = useState(false);

  useEffect(() => {
    if (document.cookie.includes("CookieBanese")) {
      setOpen(false);
    }
  }, []);

  const openPreferencesModal = () => {
    setOpenPreferences(!openPreferences);
  };

  const aceitaCookies = (
    analytics,
    personalizacao,
    marketing,
    naoCategorizado
  ) => {
    aceitaCookie(
      "cookieconsent_status_ANALYTICS",
      analytics ? "ALLOW" : "DENY",
      365,
      true, 'Lax'
    );
    aceitaCookie("cookieconsent_status_ESSENTIAL", "ALLOW", 365, true, 'Lax');
    aceitaCookie(
      "cookieconsent_status_PERSONALIZATION",
      personalizacao ? "ALLOW" : "DENY",
      365, true, 'Lax'
    );
    aceitaCookie(
      "cookieconsent_status_MARKETING",
      marketing ? "ALLOW" : "DENY",
      365, true, 'Lax'
    );
    aceitaCookie(
      "cookieconsent_status_UNCATEGORIZED",
      naoCategorizado ? "ALLOW" : "DENY",
      365, true, 'Lax'
    );

    if (analytics) {
      ReactPixel.grantConsent()
    } else {
      ReactPixel.revokeConsent();
    }

    aceitaCookie("CookieBanese", "KIUYSBN4553NNN", 999999);
  };

  const aceitaCookie = (cname, cvalue, exdays, hostOnly, sameSite) => {
    var data = new Date();
    data.setTime(data.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + data.toUTCString();
    const hostOnlyCookie = hostOnly ? `hostOnly=${hostOnly};` : undefined
    const sameSiteCookie = sameSite ? `SameSite=${sameSite};` : undefined
    const cookie = `${cname}=${cvalue};${expires};path=/;${hostOnlyCookie || ''}${sameSiteCookie || ''}`
    document.cookie = cookie
    setOpen(false);
  };

  return (
    <>
      <ContainerCookie display={open ? "flex" : "none"}>
        <ContainerConteudo>
          <InfoCookie>
            <InfoCookieDescricao>
              Com o auxílio dos cookies podemos te conhecer melhor e, assim,
              recomendar produtos e serviços que sejam do seu interesse. Ao
              clicar em "Aceitar todos os Cookies", você concorda com o uso de
              todos os cookies no site.{" "}

            </InfoCookieDescricao>

            <InfoCookieDescricao>
              <LinkPadrao to="/politica-de-cookies">Política de Cookies</LinkPadrao> | <LinkPadrao to="/politica-de-privacidade">Política de Privacidade</LinkPadrao>
            </InfoCookieDescricao>
          </InfoCookie>
          <CookieButtons>
            <Button onClick={() => openPreferencesModal()}>
              Configurar Privacidade
            </Button>
            <Button
              onClick={() => {
                aceitaCookies(true, true, true, true);
              }}
            >
              Aceitar todos os cookies
            </Button>
          </CookieButtons>
          <ContainerCookieSelect openPreferences={openPreferences}>
            <InfoCookie>
              <InfoCookieDescricao>
                Você pode selecionar os cookies que deseja permitir que este
                site utilize, com exceção dos essenciais que são necessários
                para que o site funcione corretamente. Conheça mais
                sobre a nossa política de cookies{" "}
                <LinkPadrao to="/politica-de-cookies">aqui</LinkPadrao>.
              </InfoCookieDescricao>
            </InfoCookie>
            <CookieOptionsContainer>
              <CookieOptions>
                <ContainerCookieOption>
                  <CookieCheckbox type="checkbox" checked disabled />
                  <CookieType>Essencial</CookieType>
                </ContainerCookieOption>
                <ContainerCookieOption>
                  <CookieCheckbox ref={cookieMarketingRef} type="checkbox" />
                  <CookieType>Marketing</CookieType>
                </ContainerCookieOption>
                <ContainerCookieOption>
                  <CookieCheckbox type="checkbox" ref={cookieAnalyticsRef} />
                  <CookieType>Performance</CookieType>
                </ContainerCookieOption>
                <ContainerCookieOption>
                  <CookieCheckbox
                    type="checkbox"
                    ref={cookiePersonalizacaoRef}
                  />
                  <CookieType>Personalização</CookieType>
                </ContainerCookieOption>
                <ContainerCookieOption>
                  <CookieCheckbox
                    type="checkbox"
                    ref={cookieNaoCategorizadoRef}
                  />
                  <CookieType>Não Categorizado</CookieType>
                </ContainerCookieOption>
              </CookieOptions>
              <CookieButtons>
                <Button
                  onClick={() => {
                    aceitaCookies(
                      cookieAnalyticsRef.current.checked,
                      cookiePersonalizacaoRef.current.checked,
                      cookieMarketingRef.current.checked,
                      cookieNaoCategorizadoRef.current.checked
                    );
                    setOpen(false);
                  }}
                >
                  Aceitar Selecionados
                </Button>
                <Button
                  onClick={() => {
                    openPreferencesModal();
                  }}
                >
                  Voltar
                </Button>
              </CookieButtons>
            </CookieOptionsContainer>
          </ContainerCookieSelect>
        </ContainerConteudo>
      </ContainerCookie>
    </>
  );
}

export default Cookies;

