import styled from "styled-components";

export const ContainerCookie = styled.div`
  display: ${(props) => props.display};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

export const ContainerConteudo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  position: fixed;
  bottom: 20px;
  box-shadow: 1px 1px 3px black;
  background-color: #dfdfdf;
  /* background-color: transparent; */
  color: rgba(0,0,0,0.8);
  border-radius: 12px;
  z-index: 999;
  gap: 25px;

  @media (max-width: 960px) {
    flex-direction: column;
    bottom: 0;
    height: fit-content;
  }
`
export const InfoCookie = styled.div`
  text-align: justify;
  min-width: 50%;
  margin: 0 10px;
`
export const CookieButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const ContainerInfoCookie = styled.div`
  width: 50%;
  height: 100vh;
  position: fixed;
  left: ${(props) => (props.left ? "0" : "-50%")};
  top: 0;
  background-color: #00938f;
  transition: left 1s ease-in;
  z-index: 99999;
  padding: 10px 0;

  @media (max-width: 960px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      left: ${(props) => (props.left ? "0" : "-100%")};
  }
`;

export const InfoCookieDescricao = styled.p`
  font-size: 0.9rem;
  margin-bottom: 10px;
`


export const ContainerCookieSelect = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: hidden;
  height: ${props => props.openPreferences ? '100%' : 0};
  width: 100%;
  z-index: 999999999;
  transition: height .4s ease-out;
  border-radius: 12px;
  background-color: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const ContainerCookieOption = styled.label`
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(0,0,0,0.8);
  font-size: .9rem;
  `
export const CookieOptions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 30px;
  `
export const CookieOptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `

export const CookieCheckbox = styled.input`
  height: 20px;
  width: 20px;
`

export const CookieType = styled.span`

`
export const CloseIconContainer = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  
  &:hover{
    opacity: .85;
  }
`
