import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  import IconCap from "../../content/images/png/BaneseCapIcon.png";
  import IconPig from "../../content/images/png/IconPorco.png";
  import IconMoney from "../../content/images/png/iconMoney.png";
  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';
  
  function Capitalizacao() {
    useEffect(() => {
      ReactPixel.track('Capitalizacao');
    }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo
                icone={IconCap}
                titulo="Banese Cap"
              />
              <Form nomeConsorcio="Banese Cap"></Form>
            </ContainerPage>
          </SectionForm>
          <SectionBeneficios>
            <ContainerBeneficios>
              <h3>
                Conheça todos os benefícios do Banese Capitalização
              </h3>
              <ContainerCards>
                <Card heigth="124px" icone={IconPig} conteudo="Economize dinheiro durante um prazo definido, com pagamento único ou parcelas mensais" />
                <Card heigth="124px" icone={IconMoney} conteudo="Durante a vigência do título, você participa de sorteios de até R$ 3 milhões e, ao fim do prazo, pode resgatar parte ou todo o dinheiro guardado" />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios>
        </>
      );
}

export default Capitalizacao;