import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer";

import { Container, FrameSimulador } from "./styles";

function SimuladorConsorcio() {

  window.addEventListener('message', function (e) {
    var eventName = e.data[0];
    var data = e.data[1];
    switch (eventName) {
      case 'setHeight':
        this.document.getElementById('iframe').style.height = 0
        this.document.getElementById('iframe').style.height = `${data}px`
        console.log(data)
        break;
      default:
        break;
    }
  }, false);

  return (
      <FrameSimulador
        id="iframe"
        src="https://portal.banrisulconsorcio.com.br/simuladorbanese/bbww01hw_Simulador"

      />
  );
}

export default SimuladorConsorcio;
