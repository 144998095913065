import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  
  import IconSegAuto from "../../content/images/png/SeguroAutoIcon.png";
  import IconCarro from "../../content/images/png/iconCarro.png";
  import IconCarroList from "../../content/images/png/iconCarroList.png";
  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';
  
  function SeguroAuto() {
    useEffect(() => {
      ReactPixel.track('Seguro Auto');
    }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegAuto} titulo="Seguro Auto" />
              <Form nomeConsorcio="Seguro Auto"></Form>
            </ContainerPage>
          </SectionForm>
          <SectionBeneficios>
            <ContainerBeneficios>
              <h3>Conheça todos os benefícios do nosso seguro automotivo</h3>
              <ContainerCards>
                <Card heigth="143px" icone={IconCarro} conteudo="Colisão; incendio e roubo/furto; danos materiais e corporais a terceiros e cobertura para os passageiros do seu veículo."/>
                <Card heigth="143px" icone={IconCarroList} conteudo="Carro reserva; Carta Verde (para ingressar em países do Mercosul); lucros cessantes; seguro da franquia; proteção aos vidros, lanternas, faróis e retorvisores; assistência 24h." />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios>
        </>
      );
}

export default SeguroAuto;