import React from 'react';
import {ContainerMiniCard, ConteudoMiniCard, HeaderMiniCard} from './style';

function MiniCard({icone, telefone, children}) {
  return (
      <ContainerMiniCard>
          <HeaderMiniCard>
            <img src={icone} alt=""/>
          </HeaderMiniCard>
          <ConteudoMiniCard>
            <h3>{telefone}</h3>
            {children}
          </ConteudoMiniCard>
      </ContainerMiniCard>
  );
}

export default MiniCard;