import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  
  import IconSegPet from "../../content/images/png/SeguroPetIcon.png";
  import IconSegRes from "../../content/images/png/iconSegRes.png";
  import IconCasa from "../../content/images/png/iconCasa.png";
  import IconFone from "../../content/images/png/iconFone.png";

  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';

function SeguroPet() {
  useEffect(() => {
    ReactPixel.track('Seguro Pet');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegPet} titulo="Plano Pet" /> 
              <Form nomeConsorcio="Plano Pet"></Form>
            </ContainerPage>
          </SectionForm>
        </>
      );
}

export default SeguroPet;