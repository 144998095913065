import React from "react";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LinkPadrao,
  Section,
  SectionItemLista,
  SectionLista,
  SectionTitulo,
  Texto,
  TextoSublinhado,
  Titulo
} from "../../components/common/styles/PáginaPadrao";

import { Container } from "./styles";

function PoliticaPrivacidade() {
  return (
    <Container>
      <Titulo>Política Geral de Privacidade e Proteção de Dados</Titulo>
      <Texto>
        Nós da Banese Corretora Administradora e Corretora de Seguros Ltda., com
        registro de CNPJ de nº 13.180.351/0001-73 temos um compromisso com a
        proteção dos dados pessoais utilizados em nossas atividades, com isso
        desenvolvemos esta Política de Privacidade e Proteção de Dados Pessoais
        para que você, usuário dos nossos serviços, possa entender nossas
        práticas sobre seus Dados Pessoais e como o tratamento destes dados são
        realizados.
      </Texto>
      <Texto>
        Esta política abrange nossas atividades de coleta de dados no website
        acessível no endereço{" "}
        <LinkPadrao to="/">https://www.banesecorretora.com.br/</LinkPadrao> ,
        disponibilizado e mantido por nós da Banese Corretora, que se aplica ao
        titular (pessoa natural identificada ou identificável) que interagem com
        nossos produtos e serviços como consumidores. Explica as regras sobre a
        obtenção, uso dos dados coletados, como acessar, atualizar, o
        tratamento, armazenamento, proteção dos dados, transferência,
        enriquecimento dos dados coletados dos usuários, além do registro de sua
        satisfação, de acordo com a lei em vigor no Brasil.
      </Texto>
      <TextoSublinhado>
        A Política esclarece as condições de coleta, uso, armazenamento,
        tratamento e proteção dos dados na plataforma, em conformidade com a Lei
        Geral de Proteção de dados nº 13.709 de 14 de agosto de 2018 e o Decreto
        nº 869/2018.
      </TextoSublinhado>
      <Section>
        <SectionTitulo>Definição</SectionTitulo>
        <SectionLista>
          <SectionItemLista>
            <strong>Dados Pessoais: </strong>são informações relativas a
            uma pessoa identificada ou identificável. Dados pessoais que tenham
            sido descaracterizados, codificados ou anonimizados, mas que possam
            ser utilizados para identificar uma pessoa.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Usuário: </strong>pessoas que acessam o Portal do{" "}
            <LinkPadrao to="/">Banese Corretora de Seguros</LinkPadrao>.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Controlador: </strong>“Pessoa natural ou jurídica, de
            direito público ou privado, a quem competem as decisões referentes
            ao tratamento de dados pessoais”. Em outras palavras, toda pessoa
            física ou jurídica que recolha informações pessoais é considerada um
            controlador.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Operador: </strong>é a empresa ou profissional diretamente
            responsável pelo tratamento dos dados, direcionado ou contratado
            pelo controlador. Tanto o operador quanto o controlador devem manter
            registros sobre o tratamento de dados.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Dados anonimizados: </strong>é descrito como “dado relativo
            ao titular que não possa ser identificado, considerando a utilização
            de meios técnicos razoáveis e disponíveis na ocasião de seu
            tratamento”. Em outras palavras, trata-se de uma informação que foi
            descaracterizada em algum nível para que o seu titular não possa
            mais ser identificado por meios simples, mas que ainda é importante
            para o controlador.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Consentimento: </strong>manifestação livre, informada e
            inequívoca pela qual o titular concorda com o tratamento de seus
            dados pessoais para uma finalidade determinada.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Dado sensível: </strong>são informações bem particulares e
            íntimas do titular, como dados relativos à etnia, opinião política,
            convicção religiosa ou sexual. Essas informações são tidas como
            delicadas e, por isso, o controlador só deve solicitá-las para
            finalidades bastante específicas.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Encarregado: </strong>pessoa indicada pelo controlador e
            operador para atuar como canal de comunicação entre o controlador,
            os titulares dos dados e a Autoridade Nacional de Proteção de Dados.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Tratamento: </strong>toda operação realizada com dados
            pessoais, como as que se referem a coleta, produção, recepção,
            classificação, utilização, acesso, reprodução, transmissão,
            distribuição, processamento, arquivamento, armazenamento,
            eliminação, avaliação ou controle da informação, modificação,
            comunicação, transferência, difusão ou extração.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Cookies: </strong>são pequenos arquivos que são armazenados
            no dispositivo de um navegador da internet, quando ele acessa um
            site pela primeira vez. Eles são responsáveis por coletar e
            armazenar informações sobre a navegação dentro desse ambiente,
            permitindo que elas sejam usadas posteriormente.
          </SectionItemLista>
          <SectionItemLista>
            <strong>Plataforma: </strong>site disponibilizado por meio do LinkPadrao{" "}
            <LinkPadrao to="/">https://www.banesecorretora.com.br/</LinkPadrao>.
          </SectionItemLista>
        </SectionLista>
      </Section>
      <Section>
        <SectionTitulo>Fontes de dados pessoais</SectionTitulo>
        <Texto>
          Nós coletamos seus Dados Pessoais através das seguintes fontes:
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Cotação: realizar orçamento para adesão de seguro.
          </SectionItemLista>
          <SectionItemLista>
            Seguro de Acidentes pessoais, Banese Capitalização, Consórcio,
            Previdência Privada, c. Seguro Automóvel, Seguro Banese Card, Seguro
            de Vida, Seguro para Celulares e Equipamentos Eletrônicos, Seguro
            Eventos, Seguro de Renda Garantida, Seguro Residencial e Seguro
            viagem: Informações que você fornece para realizar cotações
            especificas.
          </SectionItemLista>
          <SectionItemLista>
            Contato via WhatsApp: Disponibilizamos em nosso site o acesso ao
            nosso canal via WhatsApp.
          </SectionItemLista>
          <SectionItemLista>
            Cookies: São utilizadas para realizar métricas de performance da
            plataforma, identificar problemas no uso, captar o comportamento dos
            usuários de forma geral e coletar dados de impressão de conteúdo.
          </SectionItemLista>
        </SectionLista>
      </Section>
      <Section>
        <SectionTitulo>
          Quais são os dados pessoais que coletamos e como estes são coletados
        </SectionTitulo>
        <Texto>
          Durante sua interação junto ao nosso site, usando uma das fontes de
          coleta previamente mencionadas, podemos coletar vários dados pessoais
          seus, conforme exposto a seguir:
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Formulários de Cotação: ao preencher o formulário de cotação
            voluntariamente você estará compartilhando seus seguintes dados -
            nome completo, CPF, E-mail e número de telefone.
          </SectionItemLista>
          <SectionItemLista>
            Redirecionamento para WhatsApp: ao preencher voluntariamente o
            formulário de redirecionamento para o whatsapp os dados que você
            compartilha são nome completo, número de telefone, e-mail e número
            de CPF.
          </SectionItemLista>
          <SectionItemLista>
            Dados Pessoais Sensíveis: a Banese Corretora, não lida normalmente
            com Dados Pessoais considerados sensíveis de acordo com a legislação
            vigente, dessa forma não temos a intenção de coletar ou processor
            dados pessoais sensíveis no curso normal de suas interações com
            nossos produtos ou serviços. Quando houver necessidade de processar
            seus dados pessoais sensíveis, por qualquer motivo, nós obteremos
            seu prévio, expresso e formal consentimento para qualquer
            processamento que for voluntário. Se processarmos seus dados
            pessoais sensíveis para outras finalidades, nós nos apoiamos nas
            seguintes bases legais: (i) detecção e prevenção de crime; e (ii)
            cumprimento da lei aplicável.
          </SectionItemLista>
        </SectionLista>
        <Section>
          <SectionTitulo>
            Sobre a finalidade e uso dos dados pessoais
          </SectionTitulo>
          <Texto>
            Os itens a seguir descrevem as finalidades e os respectivos tipos de
            Dados Pessoais que nós da Banese Corretora coletaremos. Note, que
            nem todos os usos abaixo serão relevantes para todos os indivíduos e
            podem se aplicar apenas a situações específicas.
          </Texto>
          <SectionLista>
            <SectionItemLista>
              Formulários de Cotação: realizar orçamento para adesão aos seguros
              comercializados pela Banese Corretora. Desta forma, seus dados são
              informados para que possamos enviar os orçamentos de acordo com o
              seu perfil ou informações fornecidas.
            </SectionItemLista>
            <SectionLista>
              <SectionItemLista hasIconeLista>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginRight: 10 }}
                />
                Motivo para uso dos seus dados pessoais nessa situação: Enviar
                orçamento referente aos produtos disponibilizados pela Banese
                Corretora.
              </SectionItemLista>
              <SectionItemLista hasIconeLista>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginRight: 10 }}
                />
                Nossos interesses legítimos nessa situação: Disponibilizar
                valores de acordo com o perfil e necessidade do cliente.
              </SectionItemLista>
            </SectionLista>
          </SectionLista>
          <SectionLista>
            <SectionItemLista>
              Contato via WhatsApp: canal para que você possa solicitar cotação
              diretamente ao atendente de acordo com o produto desejado.
            </SectionItemLista>
            <SectionLista>
              <SectionItemLista hasIconeLista>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginRight: 10 }}
                />
                Motivo para uso dos seus dados pessoais nessa situação:
                Solicitação de cotação para os seguros.
              </SectionItemLista>
              <SectionItemLista hasIconeLista>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginRight: 10 }}
                />
                Nossos interesses legítimos nessa situação: Ofertar seguro de
                acordo com suas necessidades.
              </SectionItemLista>
            </SectionLista>
          </SectionLista>
        </Section>
      </Section>
      <Section>
        <SectionTitulo>Com quem compartilhamos</SectionTitulo>
        <Texto>
          Para a execução das atividades da plataforma, sempre que necessário,
          nós poderemos compartilhar os seus dados pessoais com outras empresas
          do grupo Banese, com prestadores de serviço, parceiros ou com órgãos
          reguladores. Apresentamos a seguir um resumo destas possibilidades:
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Com nossos parceiros quando necessário e/ou apropriado à prestação
            de serviços relacionados.
          </SectionItemLista>
          <SectionItemLista>
            Com as empresas e indivíduos contratados para a execução de
            determinadas atividades e serviços em nome da Banese Corretora.{" "}
          </SectionItemLista>
          <SectionItemLista>Com empresas do grupo Banese.</SectionItemLista>
          <SectionItemLista>
            Com fornecedores e parceiros para consecução dos serviços
            contratados com a Banese Corretora (como tecnologia da informação,
            contabilidade, entre outros).
          </SectionItemLista>
          <SectionItemLista>
            Quando necessário em decorrência de obrigação legal, determinação de
            autoridade competente, ou decisão judicial.{" "}
          </SectionItemLista>
        </SectionLista>
      </Section>
      <Section>
        <SectionTitulo>
          Sobre a divulgação, o armazenamento ou transferência dos dados
          pessoais
        </SectionTitulo>
        <Texto>
          Nós adotamos medidas adequadas para garantir que seus Dados Pessoais
          sejam mantidos de forma confidencial e segura. Entretanto, que estas
          proteções não se aplicam a informações que você tenha escolhido
          compartilhar em áreas públicas, como redes sociais de terceiros.
        </Texto>
        <Texto>
          A base de dados coletada é de nossa propriedade e responsabilidade e
          não será comercializada ou vendida para terceiros, seu uso, acesso ou
          compartilhamento será utilizado dentro dos propósitos das atividades
          da Banese Corretora podendo ser fornecido apenas mediante processo
          judicial.
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Pessoas que podem acessar seus Dados Pessoais: Seus Dados Pessoais
            serão processados por nossos colaboradores ou agentes autorizados,
            desde que estes precisem ter acesso a tais informações, dependendo
            dos propósitos específicos para os quais seus Dados Pessoais tenham
            sido coletados.
          </SectionItemLista>
          <SectionItemLista>
            Medidas tomadas em ambientes operacionais: Armazenamos seus Dados
            Pessoais em ambientes operacionais que usam medidas de segurança
            razoáveis, tanto técnicas quanto administrativas, para prevenir
            qualquer tipo de acesso não autorizado. Seguimos protocolos
            razoáveis para proteger Dados Pessoais.
          </SectionItemLista>
          <SectionItemLista>
            Transferência internacional dos seus dados: Seus dados pessoais não
            serão transferidos para outros países, excetuando-se as hipóteses
            previstas na legislação vigente, sendo observado nestas situações
            todos os requisitos estabelecidos na legislação vigente, e adotada
            as melhores práticas de mercado a fim de garantir a proteção e
            privacidade dos seus dados pessoais.
          </SectionItemLista>
          <SectionItemLista>
            Medidas que a Banese Corretora espera que você adote: É importante
            que você também tenha um papel em manter seus Dados Pessoais
            seguros. Quando criar uma conta online, por favor, assegure-se de
            escolher uma senha que seja forte para evitar que partes não
            autorizadas a adivinhem. Recomendamos que você nunca revele ou
            compartilhe sua senha com outras pessoas. Você é o único responsável
            por manter esta senha confidencial. Se você usar um computador
            compartilhado ou público, nunca escolha a opção de lembrar seu nome
            de login, endereço de e-mail ou senha, e certifique-se que você saiu
            da sua conta (realizou o log out) sempre que deixar o computador.
          </SectionItemLista>
        </SectionLista>
        <Texto>
          Nós da Banese corretora nunca solicitamos ao usuário que informe sua
          senha fora da Plataforma, por telefone, e-mail ou por qualquer outro
          meio de comunicação. A senha do usuário deverá ser usada
          exclusivamente no momento do acesso à conta do usuário na plataforma
          e, caso o usuário suspeite que sua senha tenha sido exposta a
          terceiros, recomendamos a imediata substituição.{" "}
        </Texto>
      </Section>
      <Section>
        <SectionTitulo>
          Sobre a retenção e término do tratamento de seus dados pessoais
        </SectionTitulo>
        <Texto>
          De acordo com a legislação vigente, a Banese Corretora utilizará seus
          Dados Pessoais pelo período informado em cada termo dos formulários
          existentes na aba do site ou mediante a solicitação pelo usuário,
          seguido do devido descarte.
        </Texto>
        <Texto>
          Dados Pessoais usados para fornecer uma experiência personalizada a
          você serão mantidos exclusivamente pelo tempo permitido, de acordo com
          a legislação vigente.
        </Texto>
        <Texto>
          Você pode obter maiores detalhes sobre a retenção dos seus Dados
          Pessoais através dos{" "}
          <a href="#contato">canais de comunicação detalhados nesta política</a>
          .
        </Texto>
        <Texto>
          Quando no término do tratamento de seus Dados Pessoais, estes serão
          eliminados no âmbito e nos limites técnicos das atividades, autorizada
          a conservação nas situações previstas na legislação vigente.
        </Texto>
      </Section>
      <Section>
        <SectionTitulo>
          Sobre dados pessoais de crianças e adolescentes
        </SectionTitulo>
        <Texto>
          A Banese Corretora não solicita, coleta, processa, armazena ou
          compartilha, conscientemente dados pessoais de crianças e adolescentes
          menores de idade. Se descobrirmos a ocorrência de qualquer tipo de
          manuseio dos referidos dados, forma não-intencional, removeremos os
          dados pessoais daquela criança ou adolescente de nossos registros
          rapidamente.{" "}
        </Texto>
        <Texto>
          Entretanto, nós da Banese Corretora podemos coletar dados pessoais de
          crianças e adolescentes diretamente dos seus pais ou responsáveis
          legais, e com consentimento explícito, e de acordo com as regras da
          legislação vigente.
        </Texto>
        <TextoSublinhado>
          De acordo com a lei 13.709/2018 Art.14 (§ 1º O tratamento de dados
          pessoais de crianças deverá ser realizado com o consentimento
          específico e em destaque dado por pelo menos um dos pais ou pelo
          responsável legal.)
        </TextoSublinhado>
      </Section>
      <Section>
        <SectionTitulo>Como utilizamos Cookies</SectionTitulo>
        <Texto>
          Cookies são arquivos ou informações que podem ser armazenadas em seus
          dispositivos, quando você visita as páginas da Banese Corretora.
          Geralmente, um cookie contém o nome do site que o originou, seu tempo
          de vida e um valor, que é gerado aleatoriamente.
        </Texto>
        <Texto>
          A Banese corretora utiliza cookies para facilitar o uso e melhor
          adaptar as Páginas aos seus interesses e necessidades, bem como para
          compilarmos informações sobre a utilização de nossos sites e serviços,
          auxiliando a melhorar suas estruturas e seus conteúdos. Os cookies
          também podem ser utilizados para acelerar suas atividades e
          experiências futuras nas Páginas. Saiba mais sobre nossa política de
          cookies <LinkPadrao to="/politica-de-cookies">clicando aqui</LinkPadrao>.
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Cookies Necessários: Esses cookies nos ajudam a entender como os
            visitantes interagem com as Páginas do Banese corretora fornecendo
            informações sobre as áreas visitadas, o tempo de visita ao site e
            quaisquer problemas encontrados, como mensagens de erro.
          </SectionItemLista>
          <SectionItemLista>
            Cookies Funcionais: Esses cookies permitem que as Páginas se lembrem
            de suas escolhas, para proporcionar uma experiência mais
            personalizada.{" "}
          </SectionItemLista>
          <SectionItemLista>
            Cookies Marketing: Esses cookies são utilizados para fornecer mais
            conteúdo relevante e do interesse dos Usuários. Podem ser utilizados
            para apresentar publicidade mais direcionada ou limitar o número que
            esta é veiculada, nas Páginas. Também, permitem a medição da
            eficácia de uma campanha publicitária. Ainda, esses cookies podem
            ser utilizados para indicar às Páginas e sites que o Usuário visitou
            pode compartilhar estas informações com terceiros, tais como
            agências publicitárias contratadas.
          </SectionItemLista>
          <SectionItemLista>
            Cookies de Identificação de Usuário: Os cookies de identificação de
            usuário são usados para garantir que os utilizadores só vejam a sua
            própria informação.{" "}
          </SectionItemLista>
          <SectionItemLista>
            Cookies de localização: O endereço aproximado do usuário (cidade,
            estado, país, código postal), conforme determinado pelo endereço IP,
            é armazenado para permitir selecionar automaticamente o país,
            mostrando assim quais os estabelecimentos mais próximos do usuário.
          </SectionItemLista>
          <SectionItemLista>
            Cookies da última visita e atividade: As datas das últimas visitas e
            atividades e outras informações semelhantes são gravadas para que se
            possa fornecer aos usuários atualizações sobre "o que mudou no site
            desde sua última visita", e para melhor se compreender as suas
            preferências.
          </SectionItemLista>
          <SectionItemLista>
            Cookies Flash Cookies: Os Flashs cookies são usados de forma a
            permitir a reprodução de conteúdo de áudio e vídeo.
          </SectionItemLista>
          <SectionItemLista>
            Cookies Detecção de fraude: Detectar a fraude do clique.
          </SectionItemLista>
        </SectionLista>
        <Texto>
          Por fim, lembramos que, caso o usuário não aceite alguns cookies das
          nossas páginas, certos serviços poderão não funcionar de maneira
          ideal.{" "}
        </Texto>
      </Section>
      <Section>
        <SectionTitulo>
          Sobre direitos referentes a dados pessoais
        </SectionTitulo>
        <Texto>
          A transparência sobre o tratamento dos seus dados pessoais é
          prioridade para a Banese Corretora. Além das informações
          disponibilizadas nesta Política de Privacidade, você pode também
          exercer os direitos previstos na Lei Geral de Proteção de Dados, entre
          eles:{" "}
        </Texto>
        <SectionLista>
          <SectionItemLista>
            Confirmação da existência de tratamento;
          </SectionItemLista>
          <SectionItemLista>Acesso aos dados;</SectionItemLista>
          <SectionItemLista>
            Correção de dados incompletos, inexatos ou desatualizados;
          </SectionItemLista>
          <SectionItemLista>
            Anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com a legislação vigente;{" "}
          </SectionItemLista>
          <SectionItemLista>
            Portabilidade dos dados a outro fornecedor de serviço ou produto,
            mediante requisição expressa, em conformidade com a legislação
            vigente;{" "}
          </SectionItemLista>
          <SectionItemLista>
            Eliminação dos dados pessoais tratados com o consentimento do
            titular, excetuando- se as hipóteses previstas na legislação
            vigente;{" "}
          </SectionItemLista>
          <SectionItemLista>
            Informação das entidades públicas e privadas com as quais os dados
            são compartilhados;{" "}
          </SectionItemLista>
          <SectionItemLista>
            Informação sobre a possibilidade de não fornecer consentimento e
            sobre as consequências da negativa;{" "}
          </SectionItemLista>
          <SectionItemLista>
            Revogação do consentimento para o tratamento dos seus Dados
            Pessoais, excetuando-se as hipóteses previstas na legislação
            vigente.{" "}
          </SectionItemLista>
        </SectionLista>
        <Texto>
          Estes direitos podem ser exercidos através dos canais de comunicação
          detalhados nesta política, de forma gratuita sendo necessário à
          validação da sua identidade através do fornecimento de uma cópia de
          seu RG ou meios equivalentes de identificação, em conformidade com a
          legislação vigente.{" "}
        </Texto>
        <Texto>
          Sempre que um pedido for submetido sem o fornecimento das provas
          necessárias a comprovação da legitimidade do titular dos dados, o
          pedido será automaticamente rejeitado. Ressaltamos que qualquer
          informação de identificação fornecida a Banese Corretora somente será
          processada de acordo com, e na medida permitida pelas leis vigentes.
        </Texto>
        <Texto>
          Ressaltamos que em determinados casos, não podermos excluir seus Dados
          Pessoais sem também excluir sua conta de usuário. Adicionalmente,
          algumas situações requerem a retenção de seus Dados Pessoais depois
          que você pedir sua exclusão, para satisfazer obrigações legais ou
          contratuais.{" "}
        </Texto>
        <Texto>
          A Banese Corretora faz o máximo possível para poder atender todas as
          questões que você possa ter sobre a forma a qual processamos seus
          Dados Pessoais. Contudo, se você tiver preocupações não resolvidas,
          você tem o direito de reclamar às autoridades de proteção de dados
          competentes.
        </Texto>
      </Section>
      <Section>
        <SectionTitulo>
          Alterações em nossa Política de Privacidade
        </SectionTitulo>
        <Texto>
          Sempre que nós da Banese Corretora alteramos a forma que tratamos seus
          Dados Pessoais, esta Política será atualizada. Nos reservamos o
          direito de fazer alterações às nossas práticas e a esta Política a
          qualquer tempo, desde que mantida a conformidade com a legislação
          vigente. {' '}
          <TextoSublinhado>
            Toda e qualquer alteração visa a se adequar às eventuais
            modificações em nossos Sites, sejam de mudanças para novas
            tecnologias ou sempre que for necessário, bem como a novos
            requisitos legais, regulatórios ou contratuais. Quando isso
            acontecer, informaremos na própria plataforma do site, sempre que o
            usuário acessar.
          </TextoSublinhado>
        </Texto>
      </Section>
      <Section id="contato">
        <SectionTitulo> Como entrar em contato</SectionTitulo>
        <Texto>
          Sempre que você tiver alguma dúvida sobre esta Política de
          Privacidade, mesmo após sua leitura, ou precisar interagir conosco
          sobre assuntos envolvendo os seus dados pessoais, poderá fazê-lo por
          meio dos nossos canais de atendimento disponíveis:
        </Texto>
        <SectionLista>
          <SectionItemLista>
            <strong>Encarregado pelo Tratamento de Dados Pessoais</strong>
          </SectionItemLista>
          <SectionLista>
            <SectionItemLista>
              Encarregado de dados: Sylvio Sobreira Vieira{" "}
            </SectionItemLista>
            <SectionItemLista>
              E-mail do encarregado de dados: dpo@banesecorretora.com.br
            </SectionItemLista>
          </SectionLista>
        </SectionLista>
        <Texto>
          A Banese Corretora receberá, investigará e responderá, dentro de um
          prazo considerado razoável, qualquer solicitação ou reclamação sobre a
          forma como seus Dados Pessoais são tratados, incluindo reclamações
          sobre desrespeito aos seus direitos sob as leis de privacidade e
          proteção de Dados Pessoais vigentes.
        </Texto>
        <Texto>
          Recomendamos que verifiquem a versão atualizada desta Política de
          Privacidade sempre que navegarem em nossa plataforma. Estamos sempre à
          disposição para esclarecer suas dúvidas e colocar você no controle dos
          seus dados pessoais. Contem conosco para mantê-los informados.
        </Texto>
      </Section>
      <Section>
        <SectionTitulo>Legislação de foro aplicável: </SectionTitulo>
        <Texto>
          A base legal apresentada da política de privacidade, será interpretada
          segundo a legislação brasileira pela lei 13.709/2018, na atualização
          da medida provisória nº 869/18.
        </Texto>
        <Texto>
          Este documento é regido e deve ser interpretado de acordo com as leis
          da República Federativa do Brasil. Fica eleito o Foro da Comarca de
          Sergipe, como o competente para dirimir quaisquer questões porventura
          oriundas do presente documento, com expressa renúncia a qualquer
          outro, por mais privilegiado que seja.
        </Texto>
      </Section>
      <Texto style={{ fontSize: 14, color: "gray", textAlign: "end" }}>
        Atualizado pela última vez em 03 de Novembro de 2022
      </Texto>{" "}
    </Container>
  );
}

export default PoliticaPrivacidade;
