import React, { useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Banner from "../../components/Banner";
import { ContainerHome, ContainerSlider, ContainerTotal, WrapperCards } from "./style";


import IconMaisCuidado from "../../content/images/png/Banese+CuidadoIcon.png";
import IconCap from "../../content/images/png/BaneseCapIcon.png";
import IconPrevPrivada from "../../content/images/png/BanesePrevIcon.png";
import IconConsorcio from "../../content/images/png/ConsorcioIcon.png";
import IconAcidentes from "../../content/images/png/SeguroAcidentesPessoaisIcon.png";
import IconSegAuto from "../../content/images/png/SeguroAutoIcon.png";
import IconSegBike from "../../content/images/png/SeguroBikeIcon.png";
import IconSegVidaGrupo from "../../content/images/png/SeguroDeVidaGrupoIcon.png";
import IconSegElet from "../../content/images/png/SeguroDispositivosMoveisIcon.png";
import IconSegEmpresarial from "../../content/images/png/SeguroEmpresarialIcon.png";
import IconSegEvent from "../../content/images/png/SeguroEventosIcon.png";
import IconSegPet from "../../content/images/png/SeguroPetIcon.png";
import IconSegRenda from "../../content/images/png/SeguroRendaGarantidaIcon.png";
import IconSegRes from "../../content/images/png/SeguroResidencialIcon.png";
import IconSegResCivilProf from "../../content/images/png/SeguroResponsabilidadeCivilEProfissionalIcon.png";
import IconSegTrip from "../../content/images/png/SeguroViagemIcon.png";
import IconSegVida from "../../content/images/png/SeguroVidaIcon.png";

import ReactPixel from "react-facebook-pixel";
import ServicoCard from "../../components/ServicoCard";

// const hoje = new Date()

function Home() {
  useEffect(() => {
    ReactPixel.track('Home');
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };

  return (
    <ContainerTotal>
      <ContainerSlider>
        <Slider {...settings}>
          <Banner name="banner-plantao-atendimento" image="banner-plantao-atendimento" />
          <a href='/consorcio' target='__blank'><Banner name="banner-1" image="banner01" /></a>
          <a href='/seguroempresarial' target='__blank'><Banner name="banner-2" image="banner02" /></a>
          <Banner name="banner-3" image="banner03" />
          <a href='/capitalizacao' target='__blank'><Banner name="banner-4" image="banner04" /></a>
        </Slider>
      </ContainerSlider>
      <WrapperCards>
        <ContainerHome>
          <ServicoCard
            icone={IconMaisCuidado}
            titulo="Banese +Cuidado"
            rota="/banesemaiscuidado"
            dataLancamento={new Date('2023/02/23')}
          >
            <p>O Banese +Cuidado está com você e com sua família em momentos delicados, com assistências para trazer mais tranquilidade e conforto. Além de oferecer planos personalizados para a sua necessidade, o +Cuidado possui coberturas para assistência funeral titular e familiar, descontos em exames e consultas, telemedicina, cesta básica, desconto farmácia, sorteio e auxílio despesas.</p>
          </ServicoCard>
          <ServicoCard
            icone={IconAcidentes}
            titulo="Acidentes Pessoais"
            rota="/acidentespessoais"
          >
            <p>Caiu? Quebrou? Machucou? O seguro de acidentes pessoais pode oferecer uma ampla
              cobertura para imprevistos do dia a dia, desde invalidez temporária à
              permanente, além de reembolso de despesas médicas e odontológicas e muito mais. Quer saber mais?
              Clique e preencha o cadastro abaixo.</p>
          </ServicoCard>
          <ServicoCard
            icone={IconCap}
            titulo="Banese Cap"
            rota="/capitalizacao"
          >
            <p>
              A Capitalização é uma alternativa para quem quer economizar dinheiro e formar capital para a
              aquisição programada de bens ou serviços, podendo antecipar seus objetivos através da
              participação em sorteios mensais de até R$ 3 milhões, que podem multiplicar de forma
              significativa os valores guardados. Quer saber mais? Clique abaixo e fale com um de nossos
              especialistas.
            </p>
          </ServicoCard>
          <ServicoCard
            icone={IconConsorcio}
            titulo="Consórcio"
            rota="/consorcio"
          >
            <p>
              Quer comprar o carro que deseja ou aquela casa que você sempre sonhou? Com o Consórcio
              Banese, você planeja seus sonhos e a gente ajuda a fazer acontecer. Conheça as nossas
              opções de consórcio, por segmento:
            </p>
            <ul>
              <li>
                <span>Bens Móveis:</span> possibilita a aquisição de veículos novos ou seminovos. Também podem
                ser adquiridos com cotas de auto ou moto, caminhões, ônibus, tratores, colheitadeiras,
                lanchas, jet ski, máquinas e equipamentos agrícolas, entre muitas outras opções.
              </li>
              <li>
                <span>Bens Imóveis:</span> possibilita aquisição de sala comercial, sítio, terreno urbano ou rural,
                chácara, área rural, casa na praia, casa no campo, entre outros. Também é possível
                construir, reformar ou ampliar imóveis urbanos.
              </li>
            </ul>
          </ServicoCard>
          <ServicoCard
            icone={IconPrevPrivada}
            titulo="Banese Prev"
            rota="/previdenciaprivada"
          >
            <p>A Previdência Privada é um recurso para complementar a aposentadoria, transformar planos de longo
              prazo em realidade, como quitar as prestações de um imóvel, construir uma reserva para a faculdade
              das crianças ou criar uma reserva para complementar a aposentadoria. Quer saber mais sobre os tipos
              de planos de previdência e qual se adapta melhor ao seu perfil e às suas necessidades? Clique
              abaixo e preencha o formulário, que entraremos em contato.</p>
          </ServicoCard>

          <ServicoCard
            icone={IconSegEvent}
            titulo="Seguro de Eventos"
            rota="/seguroeventos"
          >
            <p>Não deixe tudo que você organizou com tanto cuidado ir por água abaixo por causa de imprevistos.
              O Seguro Eventos é voltado para pessoas físicas e jurídicas que atuam na prestação de serviços em
              eventos, seja na organização, na promoção ou na exposição, além de poder ser contratado também
              pelos clientes finais. Oferecemos cobertura ao segurado, desde a instalação e montagem da estrutura
              do evento, até prejuízos pelo não-comparecimento do artista. Quer saber mais e conhecer todas as
              coberturas? Clique abaixo e preencha o formulário. Estamos aqui para ajudar.</p>
          </ServicoCard>
          <ServicoCard
            icone={IconSegRenda}
            titulo="Seguro Renda Garantida"
            rota="/segurorenda"
          >
            <p>Feito pra profissionais autônomos e pensado pra garantir tranquilidade
              em caso de afastamento do trabalho, acidentes ou morte. Conheça nossas coberturas
              e faça uma cotação, agora mesmo.</p>
          </ServicoCard>
          <ServicoCard
            icone={IconSegAuto}
            titulo="Seguro Auto"
            rota="/seguroauto"
          >
            <p>
              O Seguro de Automóveis pode ser contratado para qualquer veículo automotor de via
              terrestre, como carros, motos, caminhões e ônibus. Garanta coberturas em situações
              de danos acidentais, roubo ou furto do seu veículo, danos causados pelo veículo a
              terceiros (ressarcimento), acidentes com vítimas (indenização), em caso de pane
              (assistência ao veículo e a seus ocupantes), entre outras. Faça uma cotação, preenchendo
              o formulário abaixo.
            </p>
          </ServicoCard>

          <ServicoCard
            icone={IconSegVida}
            titulo="Seguro de Vida"
            rota="/segurodevida"
          >
            <p>
              O Seguro de Vida é um produto que garante proteção financeira para os familiares
              e/ou pessoas que dependem do segurado, no caso de sua falta. É um seguro que também
              pode beneficiá-lo diretamente, no caso de invalidez permanente ou de uma doença grave.
              Para auxiliar nos momentos difíceis, conte com o Seguro de Vida da Banese Corretora.
              Conheça todas as coberturas, preenchendo o formulário abaixo. Entraremos em contato.
            </p>
          </ServicoCard>


          <ServicoCard
            icone={IconSegRes}
            altText='Imagem de uma casa'
            titulo="Seguro Residencial"
            rota="/seguroresidencial"
          >
            <p>
              O Seguro Residencial cobre várias categorias de riscos, protegendo o lar 24 horas.
              Pode ser estendido à casa ou apartamento, a imóvel próprio ou alugado, localizado
              na cidade, praia ou campo. Entre as coberturas básicas, ele assegura a sua residência
              em situações de incêndio de qualquer natureza, queda de raio dentro das delimitações
              físicas do imóvel, queda de aeronaves, de explosão de qualquer espécie, entre outras.
              Este seguro também tem cobertura adicional para pagamento de aluguel e de serviços de
              assistência, como: encanador, eletricista, desentupimento, chaveiro etc. Proteja o seu
              patrimônio agora mesmo. Entre em contato com a gente e faça uma cotação .
            </p>

          </ServicoCard>
          <ServicoCard
            icone={IconSegBike}
            altText='Imagem de uma bicicleta'
            titulo="Seguro Bike"
            rota="/segurobike"
          >
            <p>
              O Seguro Bike é uma solução completa que cuida, não só da bike e do ciclista, mas também
              de danos a terceiros. Aqui você encontra coberturas para situações de furtos ou roubos,
              acidentes, danos acidentais, entre outros, e para todos os modelos de bike e perfil de
              cliente. A contratação é simples, rápida e sem burocracia. Fale com um de nossos
              especialistas e saiba mais.
            </p>

          </ServicoCard>
          <ServicoCard
            icone={IconSegElet}
            titulo="Dispositivos Móveis"
            rota="/seguroeletronico"
          >
            <p>
              O Seguro para Equipamentos Portáteis garante proteção aos eletrônicos, tais como:
              smartphones, tablets, câmeras, notebooks, netbooks, entre outros, diminuindo a exposição
              aos riscos que ocorrem no dia a dia e garantindo o pagamento de indenização por prejuízos
              materiais causados aos equipamentos até o limite contratado. Quer saber mais sobre as
              nossas coberturas? Clique abaixo.
            </p>
          </ServicoCard>
          <ServicoCard
            icone={IconSegTrip}
            titulo="Seguro Viagem"
            rota="/seguroviagem"
          >
            <p>
              Vai viajar? Então não esqueça de garantir o suporte necessário para se proteger em casos de
              imprevistos. O Seguro Viagem é um conjunto de coberturas que auxiliam o cliente em casos de
              problemas que podem ocorrer durante as viagens, a exemplo da perda e atraso de bagagem;
              cancelamento ou atraso de voos; necessidade de translado médico; cobertura com despesas médicas
              e farmacêuticas decorrentes de acidentes ou doenças contraídas; entre outras. Conte com a
              Banese Corretora para realizar uma viagem sem preocupações e com muita tranquilidade.
            </p>
          </ServicoCard>

          <ServicoCard
            icone={IconSegResCivilProf}
            titulo="RC Profissional"
            rota="/segurorcprofissional"
          >
            <p>
              O Seguro de Responsabilidade Civil Profissional é uma proteção para empreendedores e
              profissionais, como médicos, advogados, administradores, arquitetos, auditores, corretores
              de seguros, contadores, dentistas, enfermeiros, engenheiros, fisioterapeutas, notários e
              profissionais de cartórios, veterinários, e outros profissionais similares e prestadores
              de serviço, para cobrir danos causados de forma involuntária a terceiros no exercício da
              profissão. Este seguro garante reembolso a eventuais indenizações por danos corporais ou
              materiais que forem causados sem intenção. Além disso, pode cobrir, ainda, ações emergenciais
              empreendidas para tentar evitar ou minimizar tais danos.
            </p>

          </ServicoCard>

          <ServicoCard
            icone={IconSegPet}
            titulo="Plano Pet"
            rota="/planopet"
          >
            <p>
              O plano Pet é uma excelente opção para cuidar da saúde do seu animal de estimação.
              Oferecemos diferentes coberturas para atender de forma completa as necessidades de
              cada pet. A mensalidade varia de acordo com o pacote contratado e o plano fica
              responsável pelos gastos com a saúde do animal, incluindo situações de emergência.
            </p>
            <h4>Coberturas</h4>
            <ul>
              <li><a href='https://service.plamev.com.br/CoberturasPDF/ObterImagem/4/?Autorizacao=qrmLJ2bSZdAYXnWfsMmiJdmktCWJvx56sFYmfUYLrFhUt1qWrxfugCtsnC9Bc0WVov1dcdkpipqmJC9WlYfVvYokokflcnWRsOhUlqozH2Pun1UVrYtUcYWslC1vtYkWrxfulCfktCWJvx56sFYmfUYLrFhUt1qWrxfulqWptCRJvxWvsOfvJdfRHaomJC9WqrmLckWrlMUjXRAs5vxzaJOOO&_ga=2.149624630.1895515051.1656526397-117970975.1649423822&_gac=1.11341568.1656526397.EAIaIQobChMIoa30r6HT-AIVTm1vBB1M8wNpEAAYASAAEgIrlPD_BwE' target='__blank'>Advance</a></li>
              <li><a href='https://service.plamev.com.br/CoberturasPDF/ObterImagem/5/?Autorizacao=qrmLJ2bSZdAYXnWfsMmiJdmktCWJvx56sFYmfUYLrFhUt1qWrxfugCtsnC9Bc0WVov1dcdkpipqmJC9WlYfVvYokokflcnWRsOhUlqozH2Pun1UVrYtUcYWslC1vtYkWrxfulCfktCWJvx56sFYmfUYLrFhUt1qWrxfulqWptCRJvxWvsOfvJdfRHaomJC9WqrmLckWrlMUjXRAs5vxzaJOOO&_ga=2.149624630.1895515051.1656526397-117970975.1649423822&_gac=1.11341568.1656526397.EAIaIQobChMIoa30r6HT-AIVTm1vBB1M8wNpEAAYASAAEgIrlPD_BwE' target='__blank'>Platinum</a></li>
              <li><a href='https://service.plamev.com.br/CoberturasPDF/ObterImagem/6/?Autorizacao=qrmLJ2bSZdAYXnWfsMmiJdmktCWJvx56sFYmfUYLrFhUt1qWrxfugCtsnC9Bc0WVov1dcdkpipqmJC9WlYfVvYokokflcnWRsOhUlqozH2Pun1UVrYtUcYWslC1vtYkWrxfulCfktCWJvx56sFYmfUYLrFhUt1qWrxfulqWptCRJvxWvsOfvJdfRHaomJC9WqrmLckWrlMUjXRAs5vxzaJOOO&_ga=2.251286278.1895515051.1656526397-117970975.1649423822&_gac=1.216318370.1656526397.EAIaIQobChMIoa30r6HT-AIVTm1vBB1M8wNpEAAYASAAEgIrlPD_BwE' target='__blank'>Diamond</a></li>
            </ul>

          </ServicoCard>

          <ServicoCard
            icone={IconSegEmpresarial}
            titulo="Seguro Empresarial"
            rota="/seguroempresarial"
          >
            <p>
              O Seguro Empresarial protege as empresas que atuam no comércio, indústria ou prestação de
              serviço, sendo elas de pequeno, médio ou grande porte, em que as coberturas são contratadas
              de acordo com a necessidade do negócio. Com o Seguro Empresarial da Banese Corretora, a
              empresa pode contar com coberturas em situações de incêndio, roubo, acidentes naturais,
              pagamento de alugueis, entre outras, além de garantir serviços de assistência como:
              encanador, eletricista, desentupimento, chaveiro, entre outros. Quer saber mais?
              Preencha o cadastro abaixo e fale com um de nossos especialistas.
            </p>

          </ServicoCard>

          <ServicoCard
            icone={IconSegVidaGrupo}
            titulo="Seguro de Vida em Grupo"
            rota="/segurodevidaemgrupo"
          >
            <p>
              O Seguro de Vida Empresarial é uma modalidade de seguro de vida coletivo que pode ou não
              ser relacionado com o seguro contra acidentes pessoais em grupo. A sua contratação é
              realizada por empresas que têm o objetivo de proteger os empregados e executivos, bem
              como os seus beneficiários, a exemplo de cônjuges e filhos. Entre as coberturas básicas
              estão as previstas em casos de morte natural ou acidental, invalidez permanente total ou
              parcial por acidente e de antecipação de indenização em caso de doença terminal ou acidental.
              Conheça também as coberturas adicionais e faça uma cotação agora mesmo.
            </p>
          </ServicoCard>
        </ContainerHome>
      </WrapperCards>
    </ContainerTotal>
  );
}

export default Home;

