import React, { useEffect } from 'react';
import {
    ContainerPage,
    ContainerBeneficios,
    SectionBeneficios,
    SectionForm,
    ContainerCards,
  } from "../../components/common/styles/PáginaPadrao";
  
  import IconSegResCivilProf from "../../content/images/png/SeguroResponsabilidadeCivilEProfissionalIcon.png";
  import IconSegRes from "../../content/images/png/iconSegRes.png";
  import IconCasa from "../../content/images/png/iconCasa.png";
  import IconFone from "../../content/images/png/iconFone.png";

  import ConsorcioTitulo from "../../components/ConsorcioTitulo";
  import Form from "../../components/Form";
  import Card from "../../components/Card";
  import ReactPixel from 'react-facebook-pixel';

function SeguroRcProfissional() {
  useEffect(() => {
    ReactPixel.track('Seguro RC Profissional');
  }, []);

    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo icone={IconSegResCivilProf} titulo="Seguro RC Profissional" /> 
              <Form nomeConsorcio="Seguro RC Profissional"></Form>
            </ContainerPage>
          </SectionForm>
          {/* <SectionBeneficios>
            <ContainerBeneficios>
              <h3>Conheça todos os benefícios do nosso seguro RC Profissional</h3>
              <ContainerCards>
                <Card heigth="88px" icone={IconCasa} conteudo="Coberturas adicionais: danos elétricos, roubo de bens e responsabilidade civil." />
                <Card heigth="88px" icone={IconFone} conteudo="Opções de assistência 24 horas básica ou completa." />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios> */}
        </>
      );
}

export default SeguroRcProfissional;