import React, { useEffect } from 'react';
import {ContainerSucesso} from './style';
import iconOk from '../../content/images/png/iconOk.png'
import ReactPixel from 'react-facebook-pixel';

function CardSucesso({ subtitulo, mensagem }) {
  useEffect(() => {
    ReactPixel.track('SucessoForm');
  }, []);

  return (
      <ContainerSucesso>
          <img src={iconOk} alt="icon"/>
          <h1>OBRIGADO!</h1>
          <h3>{subtitulo}</h3>
          <p>{mensagem}</p>
      </ContainerSucesso>
  );
}

export default CardSucesso;