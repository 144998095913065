import React, { useEffect } from 'react';
import {
  ContainerPage,
  ContainerBeneficios,
  SectionBeneficios,
  SectionForm,
  ContainerCards,
} from "../../components/common/styles/PáginaPadrao";

import IconSegVida from "../../content/images/png/SeguroVidaIcon.png";
import IconPessoaVida from "../../content/images/png/iconPessoaVida.png";
import IconTresPessoas from "../../content/images/png/iconTresPessoas.png";
import IconLuto from "../../content/images/png/iconLuto.png";
import IconHospital from "../../content/images/png/iconHospital.png";
import IconPassaro from "../../content/images/png/iconPassaro.png";
import IconPessoaAcidente from "../../content/images/png/iconPessoaAcidente.png";
import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";
import Card from "../../components/Card";
import ReactPixel from 'react-facebook-pixel';

function SeguroDeVida() {
  useEffect(() => {
    ReactPixel.track('Seguro de Vida');
  }, []);
  
    return (
        <>
          <SectionForm>
            <ContainerPage>
              <ConsorcioTitulo
                icone={IconSegVida}
                titulo="Seguro de Vida"
              />
              <Form nomeConsorcio="Seguro de Vida"></Form>
            </ContainerPage>
          </SectionForm>
          <SectionBeneficios isSeguroDeVida={true}>
            <ContainerBeneficios>
              <h3>
                Fique por dentro de todas as vantagens do nosso seguro de vida.
              </h3>
              <ContainerCards>
                <Card titulo="Individual " heigth="200px" icone={IconPessoaVida} conteudo="Cobertura em casos de invalidez permanente (total ou parcial), doença grave ou morte." />
                <Card titulo="Acidentes pessoais " heigth="200px" icone={IconPessoaAcidente} conteudo="Cobertura em caso de sinistros provocados por acidentes." />
                <Card titulo="Vida em grupo (pessoa jurídica)" heigth="200px" icone={IconTresPessoas} conteudo="Coberturas personalizadas de acordo com cada perfil do grupo segurado ou empresa. indicado pra quem deseja oferecer proteção contra imprevistos aos colaboradores." />
                <Card titulo="Vida Mulher" heigth="200px" icone={IconLuto} conteudo="Além das coberturas convencionais, oferece proteção em caso de diagnóstico de câncer de mama, útero e ovário." />
                <Card heigth="200px" icone={IconPassaro} conteudo="Morte natural ou acidental; invalidez permanente total ou parcial por acidente; antecipação por indenização em caso de doença terminal ou acidental" />
                <Card heigth="200px" icone={IconHospital} conteudo="IFPD (Indenização Funcional Permanente Total por Doença); IPA Majorada (invalidez permanente total ou parcial por acidente); DMHO (Despesas médicas, Hospitalares e odontológicas); decessos; aquisição de jazigo; inclusão facultativa de cônjuge." />
              </ContainerCards>
            </ContainerBeneficios>
          </SectionBeneficios>
        </>
      );
}

export default SeguroDeVida;