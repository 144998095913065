import {GlobalStyle} from './styles/Global';
import Routes from './Routes/routes';
import Cookies from './components/Cookies';
import ReactPixel from "react-facebook-pixel";
import { useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";


function App() {
  
  useEffect(() => {
    const options = {
      autoConfig: true,
      debug: false,
    };
    
    ReactPixel.init('1338467739844031', options);
    ReactPixel.init('1020934515760989', options);
    if (!document.cookie.includes('CookieBanese') || document.cookie.includes('cookieconsent_status_ANALYTICS=DENY')) {
      ReactPixel.revokeConsent();
    }
   
  }, []);
  

  return (
    <div className="App">
      <GlobalStyle/>
      <BrowserRouter>
        <Routes/>
        <Cookies/>
      </BrowserRouter>
    </div>
  );
}

export default App;
