import React from "react";

import {
  Texto,
  Titulo
} from "../../components/common/styles/PáginaPadrao";

import { Container } from "./styles";

function TermosDeUso() {
  return (
    <Container>
      <Titulo>Termos de Uso</Titulo>
      <Texto>
      Nós da BANESE CORRETORA valorizamos a privacidade e proteção dos seus dados pessoais.
      Seus dados coletados neste canal de atendimento, como: nome completo, E-mail, número de
      celular, serão para única e exclusiva finalidade de realizar a cotação do seguro escolhido.
      Informamos que seus dados serão compartilhados com as seguradoras e corretores parceiros,
      os quais possuem o dever de cumprir com normas e responsabilidades pela segurança de
      privacidade dos seus dados pessoais e descartaremos seus dados pessoais após 05 anos,
      salvaguarda os armazenamentos previstos em leis e obrigações legais.
      </Texto>
      <Texto>
      Qualquer dúvida entre em contato com nosso encarregado de dados pelo canal oficial disponibilizado em nosso site.
      </Texto>
      <Texto style={{ fontSize: 14, color: "gray", textAlign: "end" }}>
        Atualizado pela última vez em 04 de Setembro de 2023
      </Texto>{" "}
    </Container>
  );
}

export default TermosDeUso;
