import {
  faFacebook, faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ContainerFooter, ConteudoFooter, Endereco } from "./style";

import LogoBanese from "../../content/images/png/logoCorretora.png";

function Footer() {
  return (
    <ContainerFooter>
      <ConteudoFooter>
        <img src={LogoBanese} alt="logo" />
        <Endereco>
          <p>Banese Administradora e Corretora de Seguros LTDA CNPJ: 13.180.351/0001-73.</p>
          <p><a href="https://www.google.com/maps/place/Av.+Dr.+Jos%C3%A9+Tomaz+D'Avila+Nabuco,+454+-+Farol%C3%A2ndia,+Aracaju+-+SE,+49030-270/@-10.974704,-37.0670009,17z/data=!3m1!4b1!4m5!3m4!1s0x71ab16b2d5432ff:0xeef6190de0f6f982!8m2!3d-10.974704!4d-37.0648122" target="blank">Av. Dr. José Thomas D’Ávilla Nabuco, 454 Conj Augusto Franco Bairro Farolândia – CEP 49030-270 – Aracaju-SE</a></p>
        </Endereco>
        <ul className="redes">
          <li>
            <a href="https://instagram.com/banesecorretora?igshid=1e86a2lq60u2g" target="blank"><FontAwesomeIcon icon={faInstagram} /></a>
          </li>
          <li>
            <a href="https://www.facebook.com/banesecorretora" target="blank"><FontAwesomeIcon icon={faFacebook} /></a>
          </li>
        </ul>
      </ConteudoFooter>

    </ContainerFooter>
  );
}

export default Footer;
