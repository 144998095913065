import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: calc(100vh + 80px);
  /* overflow: clip; */

  /* overflow-clip-margin: 150px; */
`;

export const FrameSimulador = styled.iframe`
  /* min-height: 870px; */
  margin-top: -80px;
  height: calc(100vh - 44px);
  width: 100vw;
  border: none;
  
`;
