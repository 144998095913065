import styled from 'styled-components';

export const ContainerTab = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    padding: 1rem;

    svg{
        margin-right: 1rem;
    }

`

export const ContainerDescricao = styled.div``