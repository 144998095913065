import React, { useState } from 'react';

import * as Styled from './styles';

import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../content/images/png/logoCorretora.png';

function Navbar() {
  const [open, setOpen] = useState(false)

  return (
    <Styled.Wrapper>
      <Styled.MenuContent>
        <Styled.Logo  to="/">
          <img src={Logo} alt="" />
        </Styled.Logo>
        <Styled.Menu >
          <Styled.MenuLinks >
            <Styled.MenuItem to="/quemsomos" activeClassName='active'>QUEM SOMOS?</Styled.MenuItem>
            <Styled.MenuItem to="/faleconosco" activeClassName='active'>FALE CONOSCO</Styled.MenuItem>
            <Styled.MenuItem to="/imprevistos" activeClassName='active'>EM CASO DE IMPREVISTO</Styled.MenuItem>
            <Styled.MenuItem to="/protecao-de-dados" activeClassName='active'>PROTEÇÃO DE DADOS</Styled.MenuItem>
            <Styled.MenuItem to="/consorciocliente" activeClassName='active'>CLIENTE</Styled.MenuItem>
          </Styled.MenuLinks>
          {/* <Styled.Button><FontAwesomeIcon icon={faWhatsapp} className="icon-whats"/><a className="btn-link" href={`https://api.whatsapp.com/send?phone=5579988166835`} target="blank">PEÇA AQUI SUA COTAÇÃO</a></Styled.Button> */}
        </Styled.Menu>
        <FontAwesomeIcon icon={open ? faTimes : faBars} onClick={() => setOpen(!open)}/>
      </Styled.MenuContent>
      <Styled.MobileMenu className={open ? 'active' : 'nav-menu'}>
        <FontAwesomeIcon icon={open ? faTimes : faBars} onClick={() => setOpen(!open)}/>
        <Styled.MenuItem to="/" onClick={() => setOpen(!open)} >HOME</Styled.MenuItem>
        <Styled.MenuItem to="/quemsomos" onClick={() => setOpen(!open)} >QUEM SOMOS?</Styled.MenuItem>
        <Styled.MenuItem to="/faleconosco" onClick={() => setOpen(!open)}>FALE CONOSCO</Styled.MenuItem>
        <Styled.MenuItem to="/imprevistos" onClick={() => setOpen(!open)}>EM CASO DE IMPREVISTO</Styled.MenuItem>
        <Styled.MenuItem to="/protecao-de-dados" onClick={() => setOpen(!open)}>PROTEÇÃO DE DADOS</Styled.MenuItem>
        <Styled.MenuItem to="/consorciocliente" onClick={() => setOpen(!open)}>CLIENTE</Styled.MenuItem>
        {/* <Styled.MobileMenuWhatsappButton onClick={() => setOpen(!open)}><a className="btn-link" href={`https://api.whatsapp.com/send?phone=5579988166835`} target="blank"><FontAwesomeIcon icon={faWhatsapp} className="icon-whats"/>PEÇA AQUI SUA COTAÇÃO</a></Styled.MobileMenuWhatsappButton> */}
      </Styled.MobileMenu>
    </Styled.Wrapper>
  
  );
}

export default Navbar;
