import styled from 'styled-components';

export const Container = styled.table`
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 100vw;
  overflow-x: scroll;
`

export const TableContainer = styled.table`
  border:1px solid black;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: scroll;
`

export const Head = styled.thead`
  background-color: #538135;
  padding: 0;
  `

export const HeadRow = styled.th`
  border:1px solid black;
  border-collapse: collapse;
  display: table-row;
  font-weight: bold;
  color: #fff;
  padding: 0;
  background-color: #538135;
  min-width: 100px;
  &:first-child{
    background-color: #538135;
    border-right: none;
  }
  `

export const Row = styled.tr`
  border:1px solid black;
  border-collapse: collapse;
  min-width: 100px;
  
  &:first-child{
    background-color: #538135;
    border-right: none;
  }
  `

export const Item = styled.td`
  vertical-align: middle;
  border:1px solid black;
  border-collapse: collapse;
  font-size: .9rem;
  text-align: left;
  padding: 12px;
`

export const Body = styled.tbody`
  
`
