import React, { useEffect } from "react";
import {
  ContainerBeneficios, ContainerCards, ContainerPage, SectionBeneficios,
  SectionForm
} from "../../components/common/styles/PáginaPadrao";
import { ContainerSeguroEletronico } from "./style";

import ReactPixel from 'react-facebook-pixel';
import Card from "../../components/Card";
import ConsorcioTitulo from "../../components/ConsorcioTitulo";
import Form from "../../components/Form";
import IconPC from "../../content/images/png/iconPC.png";
import IconTomada from "../../content/images/png/iconTomada.png";
import IconSegElet from "../../content/images/png/SeguroDispositivosMoveisIcon.png";

function SeguroEletronico() {
  useEffect(() => {
    ReactPixel.track('Seguro Eletrônico');
  }, []);

  return (
    <ContainerSeguroEletronico>
      <SectionForm>
        <ContainerPage>
          <ConsorcioTitulo
            icone={IconSegElet}
            titulo="Dispositivos móveis"
          />
          <Form nomeConsorcio="Dispositivos móveis"></Form>
        </ContainerPage>
      </SectionForm>
      <SectionBeneficios>
        <ContainerBeneficios>
          <h3>
            Conheça todos os benefícios do nosso seguro de equipamentos
            eletrônicos
          </h3>
          <ContainerCards>
            <Card heigth="125px" icone={IconPC} conteudo="Cobertura em casos de acidente, incêndio, queda de raio, dano em tentativa de roubo e impacto de veículos." />
            <Card heigth="125px" icone={IconTomada} conteudo="Danos elétricos; cobertura internacional; perda ou pagamento de aluguel; lentes e acessórios; subtração do bem; danos por água ou líquido" />
          </ContainerCards>
          {/* <ContainerSimulador>
          <h3>Quer conhecer melhor o nosso Seguro de Dispositivos Móveis?</h3>
          <ButtonSimulador to='/simuladorseguroeletronico' >FAÇA UMA SIMULAÇÃO</ButtonSimulador>
        </ContainerSimulador> */}
        </ContainerBeneficios>
      </SectionBeneficios>
    </ContainerSeguroEletronico>
  );
}

export default SeguroEletronico;
