import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import {
  ButtonForm,
  ContainerPage,
  ContainerPageDados,
  LinkPadrao,
  SectionContatos,
} from "../../components/common/styles/PáginaPadrao";

import IconProtecaoDados from "../../content/images/png/iconProtecaoDados.png";
import Titulo from "../../components/TituloColumn";
import CardSucesso from "../../components/CardSucesso";
import { CelMask, cpfMask } from "../../components/utils/MetodosDeRenderizacao";
import { LoadingContainer, FormContainer, TextInputsContainer, RadioInputsContainer, TextoCanalDPO } from './styles';


function ProtecaoDeDados() {
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [motivacao, setMotivacao] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [dados, setDados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conteudo, setConteudo] = useState(false);

  useEffect(() => {
    if (
      name &&
      cpf &&
      email &&
      telefone &&
      motivacao &&
      mensagem
    ) {
      setDados(true);
    } else {
      setDados(false);
    }
  }, [name, cpf, email, telefone, motivacao, mensagem]);

    const styleLoader = css`
    margin: 0 auto;
    width: 70px;
    height: 70px;
    display: flex;
    margin-top: 5rem;
    color: #00938f;
  `;

  const enviarEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_xsjvpec",
        "template_63t2wic",
        e.target,
        "user_4bJ52lJvQN4nrcOZLAWXr"
      )
      .then(
        (result) => {
          if (result.status === 200 || result.status === 204) {
            setLoading(false);
            setConteudo(true);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
          <SectionContatos>
            <ContainerPageDados>
              <Titulo icone={IconProtecaoDados} titulo={"PROTEÇÃO DE DADOS"} />
              {loading ? (
                <LoadingContainer>
                  <ClipLoader css={styleLoader} color="#00938f" />
                </LoadingContainer>
              ) : !conteudo ?(
                <FormContainer> 
                  <p>
                    Em caso de dúvidas ou queira emitir alguma solicitação para o nosso DPO, preencha os campos abaixo:
                  </p>
                  <p>
                    Campos com * são obrigatórios
                  </p>
                    <form onSubmit={enviarEmail}>
                      <TextInputsContainer>
                        <input
                          type="text"
                          placeholder="Nome*"
                          name="name"
                          value={name}
                          required
                          autoComplete="off"
                          onChange={(e) => setName(e.target.value)}
                          autoFocus
                        />
                        <input
                          type="text"
                          placeholder="Cpf*"
                          name="cpf"
                          value={cpf}
                          required
                          autoComplete="off"
                          onChange={(e) => setCpf(cpfMask(e.target.value))}
                        />
                        <input
                          type="email"
                          placeholder="Email*"
                          name="email"
                          value={email}
                          required
                          autoComplete="off"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="(00) 0 0000-0000*"
                          maxLength={15}
                          name="telefone"
                          value={telefone}
                          required
                          autoComplete="off"
                          onChange={(e) => setTelefone(CelMask(e.target.value))}
                        />
                      </TextInputsContainer>
                      <strong>Motivação*:</strong>
                      <RadioInputsContainer>
                        <div className="container-checks">
                          <div className="container1">
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="ACESSO"
                              name="motivacao"
                              required
                            />
                            <label htmlFor="termo">(I) ACESSO</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="CORREÇÃO AOS DADOS"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(II) CORREÇÃO AOS DADOS</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="ANONIMIZAÇÃO OU BLOQUEIO DOS DADOS DESNECESSÁRIOS"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(III) ANONIMIZAÇÃO OU BLOQUEIO DOS DADOS DESNECESSÁRIOS</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="CONFIRMAÇÃO DE EXISTÊNCIA DE TRATAMENTO"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(IV) CONFIRMAÇÃO DE EXISTÊNCIA DE TRATAMENTO</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="ELIMINAÇÃO DOS DADOS"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(V) ELIMINAÇÃO DOS DADOS</label>
                          </div>
                          </div>
                          <div className="container2">
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="INFORMAÇÃO DAS ENTIDADES QUE O CONTROLADOR COMPARTILHOU"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(VI) INFORMAÇÃO DAS ENTIDADES QUE O CONTROLADOR COMPARTILHOU</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="INFORMAÇÃO SOBRE NÃO FORNECER CONSENTIMENTO"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(VII) INFORMAÇÃO SOBRE NÃO FORNECER CONSENTIMENTO</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="OPOSIÇÃO AO TRATAMENTO, SE IRREGULAR"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(VIII) OPOSIÇÃO AO TRATAMENTO, SE IRREGULAR</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="PORTABILIDADE DOS DADOS A OUTRO FORNECEDOR"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(IX) PORTABILIDADE DOS DADOS A OUTRO FORNECEDOR</label>
                          </div>
                          <div className="container-check">
                            <input
                              type="radio"
                              onChange={(e) => setMotivacao(e.target.value)}
                              value="REVOGAÇÃO DO CONSENTIMENTO"
                              name="motivacao"
                            />
                            <label htmlFor="termo">(X) REVOGAÇÃO DO CONSENTIMENTO.</label>
                          </div>
                          </div>
                        </div>
                      </RadioInputsContainer>
                      <label><strong>Mensagem*: </strong></label>
                      <textarea name="mensagem" rows="6" onChange={(e) => setMensagem(e.target.value)} />
                      <div className="button-container" >
                      <ButtonForm isEnable={dados} type="submit">
                        Enviar
                      </ButtonForm>
                    </div>
                    </form>
                </FormContainer>
              ): (
                <CardSucesso
                  subtitulo="NÓS PREZAMOS PELA PROTEÇÃO DOS SEUS DADOS!"
                  mensagem="Recebemos suas informações e entraremos em contato em breve!"        
                />
              )}
              
            </ContainerPageDados>
            <TextoCanalDPO>
                Conheça também nossa política de privacidade para o <LinkPadrao to="/canal-do-dpo">Canal do DPO</LinkPadrao>
            </TextoCanalDPO>
          </SectionContatos>
    </div>
  );
}

export default ProtecaoDeDados;
