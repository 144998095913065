import styled from 'styled-components';

export const ContainerMiniCard = styled.div`
    display: flex;
    max-width: 360px;
    width: 100%;
    height: 150px;
    margin: 1rem auto;

    @media (max-width:450px){
      height: 100px;
      max-width: 320px;
      margin: 10px auto;
    }
   
`

export const HeaderMiniCard = styled.div`
    display:flex;
    align-items:center;
    background-color: #1E5F2E;
    padding: 1rem 1.5rem;
    border-radius:10px 0 0 10px;
    img{
        width: 65px;
    }
    @media(max-width:450px){
        padding: 1rem ;
        img{
            width: 35px;
        }
    }
`

export const ConteudoMiniCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #1E5F2E;
    height: 100%;
    padding: 1rem 2.2rem;
    border-radius:0 10px 10px 0;
    h3{ 
        font-size:17px;
        min-width:140px;
    }
    button{
        margin-top:20px;
        &:hover{
            background: #00868f;
        }
    }

    @media(max-width:370px){
        padding: 1rem 1.3rem;

        h3{ 
            font-size:15px;
            max-width:120px;
        }
    }
`