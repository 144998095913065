import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 0;
  padding-bottom: 30px;
  margin: 0 80px;
  
  @media(max-width:1000px){
    margin: 0 20px;
  }
  color: rgba(0,0,0,0.85);
  max-width: 1000px;
  margin: auto;
`;
export const ContainerTable = styled.div`
  margin: 30px 0;
`;
