import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  overflow: clip;
  /* overflow-clip-margin: 150px; */
`;

export const FrameSimulador = styled.iframe`
  width: 100vw;
  height: calc(100vh - 35px);
  border: none;
  margin-top: -89px;
  overflow: hidden;
`;
