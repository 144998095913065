import styled from 'styled-components';

export const ContainerTitulo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    margin: 0 auto;
    max-width: 600px;
    border-radius:10px;
    img{
        width: 280px;
    }

    h1{
        color: #1E5F2E;
    }

    @media (min-width: 320px) and (max-width: 1000px) {
        flex-direction: column;
        padding: 2rem;
    }
`