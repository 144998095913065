import React from 'react';

function Banner({ name, image }) {
  const srcSetPng = `/imagesHighPriotiry/png/@320/${image}.png 320w,
                     /imagesHighPriotiry/png/@640/${image}.png 640w,
                     /imagesHighPriotiry/png/@1024/${image}.png 1024w.
                     /imagesHighPriotiry/png/@1920/${image}.png 1920w`

  const srcSetWebp = `/imagesHighPriotiry/webp/@320/${image}.webp 320w,
                      /imagesHighPriotiry/webp/@640/${image}.webp 640w,
                      /imagesHighPriotiry/webp/@1024/${image}.webp 1024w,
                      /imagesHighPriotiry/webp/@1920/${image}.webp 1920w`

  const sizes = `(max-width: 425px) 320px, 
                 (min-width: 425px) and (max-width: 768px) 640px,
                 (min-width: 768px) and (max-width: 1024px) 1024px, 
                 1920px`

  return (
    <img
      alt={name}
      srcSet={srcSetWebp}
      sizes={sizes}
      onError={`this.onerror=null; this.srcSet=${srcSetPng}`}
    />
  );
}

export default Banner;