import styled from 'styled-components';

export const ButtonWhatsapp = styled.a`
  text-decoration:none;
  display:inline-block;
  background:#1E5F2E;
  padding:8px 15px;
  font-size:14px;
  border-radius:15px;
  color:#fff;
  font-weight:bold;
  transition: background-color .3s;
  margin-top:15px;
  &:hover{
    background-color: #004720;
  }
`