import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 0;
  padding-bottom: 30px;
  margin: 0 80px;
  
  @media(max-width:1000px){
    margin: 0 20px;
  }

  color: rgba(0,0,0,0.85);

  min-height: calc(100vh - 260px);
  max-width: 1000px;
  margin: auto;
`;

export const Titulo = styled.h1`
  line-height: 40px;
  margin: 20px 0;

  @media (max-width: 650px) {
    font-size: 1.3rem;
  }
  `