import { ContainerConsorcioCliente, IFrameConsorcioCliente } from "./styles";

function ConsorcioCliente() {
    return (
        <ContainerConsorcioCliente>
            <IFrameConsorcioCliente
                title="Portal de Auto Atendimnento do Consórcio"
                src="https://portal.banrisulconsorcio.com.br/autoatendimento.web/"
            />
        </ContainerConsorcioCliente>
    )
}

export default ConsorcioCliente;