import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`

  background-color:#F8F8F8;
  border:2px solid  #1E5F2E;
  border-radius:15px;
  height: auto;
  max-width:650px;
  width: 100%;
  padding:30px;
  margin:0 auto;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;

  @media (min-width: 750px){
    &:hover{
      transform:scale(1.02);
      background:#fff;
      border:3px solid #FFF;
    }
  }

  @media (max-width: 660px) {
    h4{
      padding-left:10px;
      margin-right:auto;
    }
    ul{
      padding-left:10px;

      margin-right:auto;
    }
  }
  @media (max-width: 500px) {
    max-width:400px;
  }
  transition:all .5s;
  
`

export const ContainerServico = styled.div`
  
  h3 {
    font-size: 35px;
    font-weight: bold;
    color:#1E5F2E;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }

  p {
    margin-top:10px;
    margin-bottom:20px;
    color:#00938f;
  }

  h4{
    margin-top:10px;
    margin-bottom:10px;
    color:#1E5F2E;
  }

  ul li span{
    font-weight: bold;
  }

  ul li{
    list-style:none;
    margin-bottom:10px;
    line-height: 25px;
    font-size:17px;
    color:#1E5F2E;
  }

  ul li a{
    text-decoration: none;
    font-weight: 500;
    color:#0000FF;
  }

  ul li a:hover{
    filter: brightness(.1);
    color:#1e5f2e;
  }

  ul li::before{
    content:'-';
    margin-right:5px;
  }
`;

export const ServicoLogo = styled.div`
  display: flex;
  flex-direction:row;
  align-items:center;
  margin:0 auto;
  padding-bottom:20px;
  width:100%;
  height:200px;
  border-bottom:2px solid #1E5F2E;
  margin-bottom:10px;

  img{
    max-width:200px;
    max-height:200px;
  }

  @media (max-width: 600px) {
    flex-direction:column;
    img{
    max-width:130px;
    max-height:130px;
  }
  }
`

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  img {
    margin-right: 1rem;
  }

  p {
    font-size: 18px;
    line-height:30px;
    color: #1E5F2E;
  }

  @media (max-width: 650px) {
    align-items: center;
    .header-servico{
        flex-direction: column;
    }
    p{
        padding: 1rem;
    }
    img {
      margin-bottom: 1rem;
      margin-right: 0;
    }
    h3{
      font-size: 25px;
    }
  }
  @media (max-width:450px){
    h3{
      font-size: 20px;
      padding-bottom: 5px;
    }
  }
`;
/*#0766308*/
export const BotaoProdutos = styled(Link)`
  background-color: #1E5F2E;
  color: #ffffff;
  font-weight:bold;
  border: none;
  border-radius: 20px;
  outline: none;
  text-decoration: none;
  padding: 16px 40px;
  cursor: pointer;
  max-width: 1180px;
  transition:background-color .3s;
  &:hover{
    background-color: #005010;
  }
  width: 100%;
  text-align:center;
  margin-top:40px;

  @media(max-width:420px){
    font-size:14px;
  }
`

export const EmBreve = styled.p`
  border: 1px solid #1E5F2E;
  color: #1E5F2E;
  font-weight:bold;
  border-radius: 20px;
  padding: 16px 40px;
  max-width: 1180px;
  transition:background-color .3s;
  width: 100%;
  text-align:center;
  margin-top:40px;

  @media(max-width:420px){
    font-size:14px;
  }
`