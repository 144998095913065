import React from 'react';
import {ContainerTitulo} from './style';

function ConsorcioTitulo({icone, titulo}) {
  return (
      <ContainerTitulo>
          <img src={icone} alt=""/>
          <h1>{titulo}</h1>
      </ContainerTitulo>
  );
}

export default ConsorcioTitulo;