import React from 'react';
import {ContainerTituloColumn} from './style';

function TituloColumn({icone, titulo, descricao}) {
  return (
      <ContainerTituloColumn>
          <img src={icone} alt=""/>
          <h1>{titulo}</h1>
          <p>{descricao}</p>
      </ContainerTituloColumn>
  );
}

export default TituloColumn;