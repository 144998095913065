import styled from 'styled-components';

export const WrapperCardsImprevisto = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  gap:20px;
  padding-bottom:40px;
  justify-items: center;

  @media(max-width:1100px){
    grid-template-columns:1fr 1fr 1fr;
  }

  @media(max-width:900px){
    grid-template-columns:1fr 1fr;
  }

  @media(max-width:670px){
    grid-template-columns:1fr;
    margin:0 auto;
    text-align:center;
  }
`

export const ContainerPage = styled.div`
  max-width: 1200px;
  padding:0 15px;
  margin: 0 auto;
  width: 100%;
  
 
   .paragrafo-contato{
     width: 60%;
     margin: 0 auto 20px auto;
     text-align:center;
   }

   @media (min-width: 320px) and (max-width: 1000px) {
     padding: 2rem;
    .paragrafo-contato{
      width: 100%;
    }
  }
`;

export const SectionContatos = styled.div`
  background-color: #d0d0d0;
  padding: 2rem;
  @media(max-width:1000px){
    padding-top: 1rem;
  }
  @media(max-width:600px){
    text-align:center;
  }
`;

export const TitleTelefone = styled.h3`
  margin-top:30px;
  margin-bottom:30px;
  font-weight:bold;
  color:#000;
  @media(min-width:1350px){
    margin-left:15px;
  }
  @media(max-width:900px){
    text-align:center;
  }
`;
