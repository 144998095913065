import styled from "styled-components";

export const LoadingContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  input {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    outline: none;
    border: transparent;
    width: 50%;
    border:2px solid #fff;
    &:focus{
      border:2px solid #1E5F2E;
    }
  }

  .container-select {
    margin-bottom: 1rem;
    width: 50%;

    label {
      margin-bottom: 10px;
    }

    select {
      color: gray;
      padding: 1rem;
      border-radius: 8px;
      background-color: #ffffff;
      width: 100%;
      outline: none;
      border: 2px solid #fff;
      &:focus{
        border:2px solid #1E5F2E;;
      }
    }
  }
  .paragrafo{
    width:50%;
    text-align: right;

    p {
    margin-bottom: 1rem;
    font-size:13px;
    display:block;
  }
  }
  

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }

  .container-check-termo,
  .container-check-infos,
  .container-check-aceite,
  .container-check-conveniado-oab {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    input {
      width: auto;
      margin-bottom: 0;
    }
    label {
      text-align: justify;
      margin-left: 1rem;
    }
  }

  .container-select {
    
    label {
      display: block;
      text-align: left;
    }
    @media(max-width:1000px){
      width: 100%;
    }
  }

  .container-checks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
  }

  @media (min-width: 320px) and (max-width: 1000px) {
    input,
    .button,
    select {
      width: 100%;
    }
    .container-checks {
      width: 100%;
    }
  }
`;
